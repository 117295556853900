import { createReducer } from '@reduxjs/toolkit';
import {
  clearDestination,
  fetchDestination,
  fetchDestinationFailure,
  setDestination,
  clearMerchandise,
  fetchMerchandise,
  fetchMerchandiseFailure,
  setMerchandise,
} from './destinationActions';
import { STORE_STATUS } from 'lib/enums/store';

const initialState = {
  destination: {
    status: STORE_STATUS.PENDING,
  },
  merchandise: {
    status: STORE_STATUS.PENDING,
  },
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(fetchDestination, (state) => {
      state.destination.status = STORE_STATUS.PENDING;
    })
    .addCase(fetchDestinationFailure, (state, { payload }) => {
      state.destination.error = payload;
      state.destination.status = STORE_STATUS.FAILED;
    })
    .addCase(setDestination, (state, { payload }) => {
      state.destination = {
        ...payload,
        error: null,
        status: STORE_STATUS.RESOLVED,
      };
    })
    .addCase(clearDestination, (state) => {
      state.destination = initialState.destination;
    })
    .addCase(fetchMerchandise, (state) => {
      state.merchandise.status = STORE_STATUS.PENDING;
    })
    .addCase(fetchMerchandiseFailure, (state, { payload }) => {
      state.merchandise.error = payload;
      state.merchandise.status = STORE_STATUS.FAILED;
    })
    .addCase(setMerchandise, (state, { payload }) => {
      state.merchandise = {
        ...payload,
        error: null,
        status: STORE_STATUS.RESOLVED,
      };
    })
    .addCase(clearMerchandise, (state) => {
      state.merchandise = initialState.merchandise;
    }),
);
