import { createSelector } from '@reduxjs/toolkit';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';
import { getPropertyId, getRoomTypesWithoutOffers } from 'store/property/propertySelectors';

export const getIsLoading = (state) => get(state, 'exclusiveOffer.isLoading');
export const getExclusiveOffer = (state) => get(state, 'exclusiveOffer.exclusiveOffer');
export const getExclusiveOfferAvailability = (state) => get(state, 'propertyAvailability.roomTypes', []);
export const getExclusiveOfferAvailabilityQuery = (state) => get(state, 'propertyAvailability.meta.query', {});
export const getLeadInOffer = createSelector(getExclusiveOffer, (exclusiveOffer) => exclusiveOffer?.rooms?.[0]?.offers?.[0]);

export const getExclusiveOfferRooms = createSelector(getExclusiveOffer, (exclusiveOffer) => exclusiveOffer?.rooms);
export const getExclusiveOfferRoomTypeCount = createSelector(getExclusiveOfferRooms, (rooms) => rooms?.length || 0);

export const getExclusiveOffersList = (state) => get(state, 'exclusiveOffer.exclusiveOffersList');
export const getIsExclusiveOfferAvailable = createSelector(
  getPropertyId,
  getExclusiveOffersList,
  (propertyId, exclusiveOffersList = []) => {
    if (!isEmpty(exclusiveOffersList)) {
      return exclusiveOffersList.some(({ propertyId: offerPropertyId }) => offerPropertyId === propertyId);
    }
    return false;
  },
);

export const getAllAvailableOffers = createSelector(getExclusiveOfferAvailability, (roomTypesList) => {
  const allOffers = roomTypesList.reduce((roomAccum, { offers }) => {
    const offersForThisRoom = offers.reduce((offerAccum, offer) => ({ ...offerAccum, [offer.id]: offer }), {});
    return { ...roomAccum, ...offersForThisRoom };
  }, {});
  return allOffers;
});

export const getExclusiveOfferAvailabilityCount = createSelector(
  getExclusiveOfferRooms,
  getAllAvailableOffers,
  (exclusiveRooms = [], availableOffers = {}) => {
    const availableOfferIds = Object.keys(availableOffers);
    return exclusiveRooms.reduce((counter, room) => {
      const roomOfferId = room?.offers[0]?.id;
      return counter + (availableOfferIds.includes(String(roomOfferId)) ? 1 : 0);
    }, 0);
  },
);

export const getExclusiveOfferInclusions = createSelector(getExclusiveOffer, (offer) => {
  const inclusions = reduce(
    offer?.highlights?.inclusions,
    (accum, inclusion) => {
      return [...accum, inclusion.name];
    },
    [],
  );
  return inclusions;
});

export const getExclusiveOfferRoomTypes = createSelector(
  getExclusiveOfferRooms,
  getRoomTypesWithoutOffers,
  (exclusiveRoomTypes = [], roomTypesWithoutOffers = []) =>
    reduce(
      exclusiveRoomTypes,
      (cards, roomType) => {
        if (roomType?.offers.length === 0) return cards;

        const roomDetails = find(roomTypesWithoutOffers, ['id', roomType?.extranet?.id]);
        const firstOffer = roomType.offers[0];
        const { adults = 0, children = 0, minNumberOfNights, offerTotal, valuedAtTotal = null, title, description } = firstOffer;

        const card = {
          ...omit(roomDetails, 'description'),
          ...omit(roomType, 'offers'),
          title,
          description,
          adults,
          children,
          inclusions: firstOffer.inclusions || [],
          offerId: firstOffer.id,
          maxOccupantCount: adults + children,
          minNumberOfNights,
          offerTotal,
          valuedAtTotal,
        };

        return [...cards, card];
      },
      [],
    ),
);

export const getExclusiveOfferSanityGa4Pricing = createSelector(getExclusiveOfferRooms, (rooms) => {
  const roomOffers = reduce(
    rooms,
    (accum, room) => {
      const offerDetails = reduce(
        room.offers,
        (accum, offer) => {
          const offerPricing = {
            offerTotal: offer?.offerTotal?.amount,
            valuedAtTotal: offer?.valuedAtTotal?.amount || 0,
            nights: offer?.minNumberOfNights,
            adults: offer?.adults,
            children: offer?.children,
          };
          return [...accum, offerPricing];
        },
        [],
      );
      return [...accum, ...offerDetails];
    },
    [],
  );

  const roomCount = rooms?.length;
  const offerCount = roomOffers?.length;

  const averagePrice = Math.floor(
    reduce(
      roomOffers,
      (accum, offer) => {
        const totalPayable = Number(offer?.offerTotal) || 0;
        return accum + totalPayable;
      },
      0,
    ) / offerCount,
  );

  const averagePriceBeforeDiscount = Math.floor(
    reduce(
      roomOffers,
      (accum, offer) => {
        const totalBeforeDiscount = Number(offer?.valuedAtTotal) || 0;
        return accum + totalBeforeDiscount;
      },
      0,
    ) / offerCount,
  );
  return {
    averagePrice: averagePrice,
    averagePriceBeforeDiscount: averagePriceBeforeDiscount,
    roomCount: roomCount,
    offerCount: offerCount,
  };
});

export const getExclusiveOffersAvailableGa4Pricing = createSelector(
  getAllAvailableOffers,
  getExclusiveOfferRoomTypes,
  (availableOffers, exclusiveOfferRoomTypes) => {
    const propertyOfferIds = reduce(
      exclusiveOfferRoomTypes,
      (accum, roomType) => {
        const roomOfferIds = reduce(
          roomType.exclusiveOffers,
          (accum, offer) => {
            return [...accum, offer.id];
          },
          [],
        );
        return [...accum, ...roomOfferIds];
      },
      [],
    );

    const availableExclusiveOffers = reduce(
      propertyOfferIds,
      (accum, offerId) => {
        const { [offerId]: offer } = availableOffers;
        if (offer === undefined) {
          return accum;
        } else {
          return [...accum, offer];
        }
      },
      [],
    );

    const roomCount = exclusiveOfferRoomTypes?.length;
    const offerCount = availableExclusiveOffers?.length;

    const averagePrice = Math.floor(
      reduce(
        availableExclusiveOffers,
        (accum, offer) => {
          const totalPayable = Number(offer?.charges?.total?.amount) || 0;
          return accum + totalPayable;
        },
        0,
      ) / offerCount,
    );

    const averagePriceBeforeDiscount = Math.floor(
      reduce(
        availableExclusiveOffers,
        (accum, offer) => {
          const totalBeforeDiscount = Number(offer?.charges?.total?.amount) + Number(offer?.charges?.totalDiscount?.amount) || 0;
          return accum + totalBeforeDiscount;
        },
        0,
      ) / offerCount,
    );

    const averagePointsEarned = Math.floor(
      reduce(
        availableExclusiveOffers,
        (accum, offer) => {
          const pointsEarned = offer?.pointsEarned?.qffPoints?.total || 0;
          return accum + pointsEarned;
        },
        0,
      ) / offerCount,
    );

    return {
      averagePrice: averagePrice,
      averagePriceBeforeDiscount: averagePriceBeforeDiscount,
      averagePointsEarned: averagePointsEarned,
      roomCount: roomCount,
      offerCount: offerCount,
    };
  },
);
