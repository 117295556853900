import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { getPageFromState, getPropertyAvailabilityCounts } from './helpers';
import { fetchPropertyAvailabilitySuccess } from 'store/propertyAvailability/propertyAvailabilityActions';
import { getExclusiveOffer } from 'store/exclusiveOffer/exclusiveOfferSelectors';

const mapQueryParamToGTMVariables = ({ adults, children, infants, checkIn, checkOut, location }) => ({
  numAdults: adults,
  numChildren: children,
  numInfants: infants,
  checkIn,
  checkOut,
  location,
});

const emitFetchPropertyAvailabilitySuccessEvent = (action, prevState) => {
  const { roomTypes, queryParams } = action.payload;
  const page = getPageFromState(prevState);
  const exclusiveOffer = getExclusiveOffer(prevState);
  const counts = getPropertyAvailabilityCounts(roomTypes, exclusiveOffer);

  const mappedGTMVariables = mapQueryParamToGTMVariables(queryParams);
  const customAttributes = {
    ...mappedGTMVariables,
    availableOfferCount: counts.offers,
    availableRoomTypeCount: counts.rooms,
    isExclusive: !!exclusiveOffer,
  };

  const eventType = counts.offers > 0 ? 'Successful Search' : 'Unsuccessful Search';

  return interactionEvent({ type: eventType, value: queryParams.location, page: page.name, customAttributes });
};

export default { [fetchPropertyAvailabilitySuccess]: emitFetchPropertyAvailabilitySuccessEvent };
