import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { Box } from '@qga/roo-ui/components';

const StyledBox = styled(Box)`
  white-space: pre-line;
`;

const Markup = ({ content }) => {
  return <StyledBox>{parse(content || '')}</StyledBox>;
};

Markup.propTypes = {
  content: PropTypes.string,
};

Markup.defaultProps = {
  content: '',
};

export default Markup;
