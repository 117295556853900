export const shadows = {
  soft: '0 1px 20px rgba(0, 0, 0, 0.15)',
  hard: '0 1px 2px 0 rgba(0, 0, 0, 0.2);',
  modal: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
  result: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
  footer: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  small: '0 0 8px 0 rgba(0,0,0,0.1)',
  hover: '0 1px 20px rgba(0, 0, 0, 0.15)',
  tooltip: '0px 0px 15px 0px rgba(0, 0, 0, 0.1)',
  default: '0 0 8px 0 rgba(0,0,0,0.1)',
};
