import { createSelector } from '@reduxjs/toolkit';
import Decimal from 'decimal.js';
import pick from 'lodash/pick';
import { getQueryParams } from 'store/router/routerSelectors/getQueryParams';
import { numberFromString } from 'lib/number';
import { getTotalPayableAtBooking } from 'store/quote/quoteSelectors';
import { DEPOSIT_PAY_PERCENTAGE } from 'config';

export const getFormData = (state) => state?.checkout?.formData;
export const getPayments = (state) => state?.checkout?.formData?.payments;
export const getIsPaymentMethodChanged = (state) => state?.checkout?.formData?.isPaymentMethodChanged;
export const getBookingClientRequestId = (state) => state?.checkout?.bookingClientRequestId;
export const getBookingChannel = (state) => state?.checkout?.bookingChannel;

export const getPayWith = createSelector(getQueryParams, (queryParams) => queryParams.payWith);

export const getPaymentMode = createSelector(getFormData, (formData) => formData?.paymentMode);

export const getTravelArranger = createSelector(getFormData, (formData) =>
  pick(formData, ['title', 'firstName', 'lastName', 'emailAddress']),
);

export const getInitialCashAmount = createSelector(getQueryParams, (queryParams) => {
  const amount = numberFromString(queryParams.initialCash);
  return new Decimal(amount || 0);
});

export const getVoucherAmount = createSelector(getPayments, (payments) => payments?.voucher?.amount ?? new Decimal(0));

export const getTravelPassCard = createSelector(getPayments, (payments) => {
  const travelPass = payments?.travelPass;
  return {
    ...travelPass,
    amount: travelPass?.amount ?? new Decimal(0),
  };
});

export const getTravelPassAmount = createSelector(getPayments, (payments) => payments?.travelPass?.amount ?? new Decimal(0));

export const getPointsAmount = createSelector(getPayments, (payments) => payments?.points?.amount ?? new Decimal(0));

export const getPointsAmountInCash = createSelector(getPayments, (payments) => payments?.points?.amountInCash ?? new Decimal(0));

export const getPayableNowCashAmount = createSelector(getPayments, (payments) => payments?.cash?.payableNow?.amount ?? new Decimal(0));

export const getPayableLaterCashAmount = createSelector(getPayments, (payments) => payments?.cash?.payableLater?.amount ?? new Decimal(0));

export const getTotalPayableCashAmount = createSelector(
  getPayableNowCashAmount,
  getPayableLaterCashAmount,
  (payableNowCashAmount, payableLaterCashAmount) => payableNowCashAmount.plus(payableLaterCashAmount),
);

export const getRequiresCreditCardPayment = createSelector(getTotalPayableCashAmount, (cashTotal) => cashTotal.greaterThan(0));

export const getTotalPayableAfterVoucher = createSelector(
  getTotalPayableAtBooking,
  getVoucherAmount,
  (totalPayableAtBooking, voucherAmount) => totalPayableAtBooking.minus(voucherAmount),
);

export const getTotalPayableAfterCredits = createSelector(
  getTotalPayableAtBooking,
  getVoucherAmount,
  getTravelPassAmount,
  (totalPayableAtBooking, voucherAmount, travelPassAmount) => totalPayableAtBooking.minus(voucherAmount).minus(travelPassAmount),
);

export const getMinimumDepositAmount = createSelector(getTotalPayableAtBooking, (totalPayableAtBooking) => {
  return totalPayableAtBooking.times(DEPOSIT_PAY_PERCENTAGE).dividedBy(100);
});

export const getCreditcardSessionData = createSelector(getPayments, (payments) => payments?.cash?.creditCard || {});

export const getIsCreditCardValid = createSelector(
  getCreditcardSessionData,
  getTotalPayableCashAmount,
  (sessionData, totalPayableCashAmount) =>
    totalPayableCashAmount.isZero() ||
    !!(
      sessionData.sessionId &&
      sessionData.cardNumber &&
      sessionData.expiryMonth &&
      sessionData.expiryYear &&
      sessionData.securityCode &&
      sessionData.nameOnCard
    ) ||
    !!(
      sessionData.encryptedCardNumber &&
      sessionData.encryptedExpiryMonth &&
      sessionData.encryptedExpiryYear &&
      sessionData.encryptedSecurityCode &&
      sessionData.bin &&
      sessionData.holderName
    ),
);
