import React from 'react';

import { Box } from '@qga/roo-ui/components';
import Footer from 'components/Footer';
import Header, { UpdatedHeader } from 'components/Header';
import ErrorBoundary from 'components/errors/ErrorBoundary';
import TreatmentToggle from 'components/TreatmentToggle/TreatmentToggle';

interface LayoutProps {
  children: React.ReactNode;
}

const DefaultLayout = ({ children }: LayoutProps) => {
  return (
    <>
      <Box flex="1 0 auto" width="100%" bg="greys.porcelain">
        <TreatmentToggle split="jh_header_nav" treatment="off">
          <Header />
        </TreatmentToggle>
        <TreatmentToggle split="jh_header_nav" treatment="on">
          <UpdatedHeader />
        </TreatmentToggle>
        <ErrorBoundary>{children}</ErrorBoundary>
      </Box>
      <Footer />
    </>
  );
};

export default DefaultLayout;
