import { createReducer } from '@reduxjs/toolkit';
import { clearErrors, apiRequestFailure } from './errorActions';

const initialState = {
  apiError: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearErrors, (state) => {
      state.apiError = null;
    })
    .addCase(apiRequestFailure, (state, { payload }) => {
      state.apiError = payload;
    }),
);
