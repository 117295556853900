import * as client from 'lib/clients/searchPropertyAvailability';
import * as actions from 'store/propertyAvailability/propertyAvailabilityActions';
import tierInstance from 'store/pointsConversion/tierInstance';
import { setBookingChannel } from 'store/checkout/checkoutActions';
import { getPropertyId, getIsExclusive } from 'store/property/propertySelectors';
import { getHasValidQuery } from 'store/propertyAvailability/propertyAvailabilitySelectors';
import { getQueryParams, getFullKnownQuery } from 'store/router/routerSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { setPointsLevels } from 'store/pointsConversion/pointsConversionActions';
import { createAsyncLogic } from 'lib/logic';
import { LUXE_BRAND_BOOKING_CHANNEL, HOTELS_BRAND_BOOKING_CHANNEL } from 'config';

export const fetchPropertyAvailabilityLogic = createAsyncLogic({
  type: actions.fetchPropertyAvailability,
  latest: true,
  validate: ({ getState }, allow, reject) => {
    getHasValidQuery(getState()) ? allow() : reject();
  },
  async process({ getState }, dispatch) {
    dispatch(actions.setLoading(true));

    const state = getState();
    const propertyId = getPropertyId(state);
    const query = getFullKnownQuery(state);
    const queryParams = getQueryParams(state);
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const isExclusive = getIsExclusive(state);
    const bookingChannel = isExclusive ? LUXE_BRAND_BOOKING_CHANNEL : HOTELS_BRAND_BOOKING_CHANNEL;

    const availabilityResponse = await client.searchPropertyAvailability({
      ...query,
      propertyId,
      accessToken,
      flightBookerToken,
      qhUserId,
    });

    const { roomTypes, meta } = availabilityResponse;
    dispatch(actions.fetchPropertyAvailabilitySuccess({ roomTypes, meta, queryParams }));
    dispatch(setBookingChannel(bookingChannel));

    const activeTierInstance = tierInstance(meta.pointsTierInstances);

    dispatch(setPointsLevels(activeTierInstance));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchPropertyAvailabilityFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
