import * as actions from 'store/destination/destinationActions';
import * as client from 'lib/clients/fetchDestination';
import { createAsyncLogic } from 'lib/logic';

export const fetchDestinationLogic = createAsyncLogic({
  type: actions.fetchDestination,
  latest: true,
  async process({ action }, dispatch) {
    const { payload } = action;
    const destination = await client.fetchDestination(payload);
    dispatch(actions.setDestination(destination));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchDestinationFailure(parsedError));
  },
});
