import { createReducer } from '@reduxjs/toolkit';
import { setPointsLevels, clearPointsLevels } from './pointsConversionActions';

const initialState = {
  name: null,
  levels: [],
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPointsLevels, (state, { payload }) => {
      state.name = payload.name;
      state.levels = payload.levels;
    })
    .addCase(clearPointsLevels, (state) => {
      state.name = null;
      state.levels = [];
    }),
);
