import get from 'lodash/get';
import { dateFromString } from 'lib/date';

export const getFullscreenGalleryContent = (state) => state.ui.fullscreenGalleryContent;
export const getIsPointsPay = (state) => state.ui.isPointsPay;
export const getIsMobileApp = (state) => state.ui.isMobileApp;
export const getMapHoveredPropertyId = (state) => state.ui.mapHoveredPropertyId;
export const getMapActivePropertyId = (state) => state.ui.mapActivePropertyId;

export const getDateChangeMessaging = (state) => ({
  isVisible: !!get(state, 'ui.dateChangeMessaging.isVisible'),
  checkIn: dateFromString(get(state, 'ui.dateChangeMessaging.checkIn')),
  checkOut: dateFromString(get(state, 'ui.dateChangeMessaging.checkOut')),
});

export const getAppVersion = (state) => state.ui?.appVersion;
export const getAppOutOfDate = (state) => !!state.ui?.appOutOfDate;
