import { theme as baseTheme } from '@qga/roo-ui';
import merge from 'lodash/merge';

// Sourced from https://github.com/hooroo/jetstar-holidays-ui/blob/master/src/lib/theme/colors.js
export const brandColors = {
  black: '#111111',
  orange: '#FF5115',
  paleOrange: '#FFF6F3',
  darkOrange: '#E13A00',
  blue: '#0692FC',
  yellow: '#FDA108',
  purple: '#3B3697',
  sunset: '#F03C00',
  charcoal: '#222222',
  iron: '#444444',
  steel: '#707070',
  cerulean: '#007DDB',
  grasshopper: '#00C249',
  pink: '#FFEDED',
  lightGreen: '#F2FFF1',
  legendGreen: '#029967',
  cream: '#FFF2CC',
  sky: '#E3F3FC',
  white: '#FFFFFF',
  brightGrey: '#EEEEEE',
  notWhite: '#F7F7F7',
  smoke: '#DEDEDE',
  grey: '#AAAAAA',
  red: '#FE0002',
  qantasRed: '#E40000',
  qantasDarkRed: '#B10000',
};

export const colors = merge({}, baseTheme.colors, {
  green: brandColors.legendGreen,
  lightOrange: brandColors.cream,
  lightBlue: brandColors.sky,
  brightSun: brandColors.yellow,
  snow: '#fbfbfb',
  semiTransparentDark: 'rgba(0, 0, 0, 0.9)',
  greys: {
    charcoal: brandColors.charcoal,
    alto: brandColors.smoke,
    iron: brandColors.iron,
    steel: brandColors.steel,
    grey: brandColors.grey,
  },
  brand: {
    primary: brandColors.orange,
    secondary: brandColors.blue,
  },
  ui: {
    link: brandColors.blue,
    linkHover: brandColors.cerulean,
    linkFocus: brandColors.blue,
    errorBackground: brandColors.pink,
    successBackground: brandColors.lightGreen,
    infoBackground: brandColors.sky,
    error: brandColors.red,
    badgeText: brandColors.white,
    badgeBackground: brandColors.blue,
    iconBadge: brandColors.white,
    iconBadgeBackground: brandColors.orange,
    qffLogin: baseTheme.colors.white,
    qffLoginBackground: baseTheme.colors.red,
  },
  external: {
    trivago: '#37454d',
    tripAdvisor: '#00a680',
    google: '#4885ed',
  },
  gradients: {
    transparentToGrey: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(244,245,246, 1) 90%)',
    transparentToWhite: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%)',
  },
});
