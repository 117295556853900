import { rem } from 'polished';

export const fontSizes = {
  xs: rem('14px'),
  sm: rem('16px'),
  base: rem('18px'),
  md: rem('20px'),
  lg: rem('24px'),
  xl: rem('32px'),
  '2xl': rem('34px'),
  '3xl': rem('36px'),
  '4xl': rem('46px'),
};
