import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchMerchandise = async ({ regionId, limit, subRegionsLimit }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/merchandising`,
    method: 'GET',
    params: {
      regionId,
      limit,
      subRegionsLimit,
    },
  });

  return response?.data;
};
