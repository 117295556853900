import { addPaymentInfo } from 'store/checkout/checkoutActions';
import { EventType, createEvent } from '@qantasexperiences/analytics';

const emitAddPaymentInfo = ({ payload }) => {
  if (payload.isGA4) {
    const addPaymentInfoEvent = createEvent({
      type: EventType.ADD_PAYMENT_INFO,
      payload: {
        quote: payload.quote,
        payments: payload.payments,
        pointsConversion: payload.pointsConversion,
        isRebooked: payload.isRebooked,
      },
    });

    return addPaymentInfoEvent;
  }
};

export default { [addPaymentInfo]: emitAddPaymentInfo };
