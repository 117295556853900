import { createLogic } from 'redux-logic';
import get from 'lodash/get';
import {
  login,
  logout,
  dismissSearchMessaging,
  setUser,
  setAuthentication,
  clearAuthentication,
  dismissLoginTooltip,
  dismissWelcomeMessage,
  setAuthenticationCompleted,
} from 'store/user/userActions';
import { trackEvent } from 'store/split/splitActions';
import { fetchSearchResults } from 'store/search/searchActions';
import { fetchDeals } from 'store/promoArea/promoAreaActions';
import { fetchPropertyAvailability } from 'store/propertyAvailability/propertyAvailabilityActions';
import { createQuote } from 'store/quote/quoteActions';
import { setIsPointsPay } from 'store/ui/uiActions';
import { COOKIE_NAMES, COOKIE_MAX_AGES, COOKIE_PATHS } from 'lib/enums/cookies';
import { MIN_POINTS_AMOUNT, OAUTH_ENABLED } from 'config';
import { LOCATION_CHANGE } from 'connected-next-router';
import { getTreatments } from 'store/split/splitSelectors';
import { getQffAuth } from 'lib/qffAuth';
import authenticateUserOAuth from 'lib/oauth/authenticateUserOAuth';

const refreshData = ({ dispatch, state, payload }) => {
  const pathname = get(state, 'router.location.pathname');
  if (payload && payload.pointsBalance?.lessThan?.(MIN_POINTS_AMOUNT)) dispatch(setIsPointsPay(false));

  if (pathname.startsWith('/search/list')) {
    dispatch(fetchSearchResults());
    dispatch(fetchDeals());
  } else if (pathname.startsWith('/properties')) {
    dispatch(fetchPropertyAvailability());
  } else if (pathname.startsWith('/checkout')) {
    dispatch(createQuote());
  }
};

export const loginLogic = createLogic({
  type: login,
  latest: true,
  async process({ action, getState }, dispatch, done) {
    dispatch(setAuthentication(action.payload));
    refreshData({ dispatch, state: getState(), payload: action.payload });
    dispatch(trackEvent({ name: 'login' }));

    done();
  },
});

export const logoutLogic = createLogic({
  type: logout,
  latest: true,
  async process({ getState }, dispatch, done) {
    dispatch(clearAuthentication());
    refreshData({ dispatch, state: getState() });
    done();
  },
});

// sets a cookie in the format dropped by qantas.com. Discussions are being held with the SSO team
// around the potential centralisation of this so that it's better managed and each team is less open
// to be impacted by changes made by the other
export const setAuthenticationLogic = createLogic({
  type: setAuthentication,
  latest: true,
  async process({ action, cookies }, _, done) {
    cookies.set(COOKIE_NAMES.ACCESS_TOKEN, `${action.payload.memberId}|${action.payload.accessToken}`, {
      path: '/',
      encode: (value) => value,
    });

    done();
  },
});

export const clearAuthenticationLogic = createLogic({
  type: clearAuthentication,
  latest: true,
  process({ cookies }, _, done) {
    cookies.set(COOKIE_NAMES.ACCESS_TOKEN, null, {
      path: '/',
      expires: new Date(1970, 1, 1),
    });
    cookies.set(COOKIE_NAMES.QH_AUTH_MEMBER_ID, null, {
      path: '/',
      expires: new Date(1970, 1, 1),
    });

    done();
  },
});

export const dismissSearchMessagingLogic = createLogic({
  type: dismissSearchMessaging,
  latest: true,
  async process({ cookies }, dispatch, done) {
    cookies.set(COOKIE_NAMES.HIDE_SEARCH_MESSAGING, 'true', {
      maxAge: COOKIE_MAX_AGES.SEVEN_DAYS_IN_SECONDS,
      path: COOKIE_PATHS.HOTELS,
      sameSite: 'strict',
    });
    dispatch(setUser({ hasDismissedSearchMessaging: true }));
    done();
  },
});

export const dismissLoginTooltipLogic = createLogic({
  type: dismissLoginTooltip,
  latest: true,
  async process({ cookies }, dispatch, done) {
    cookies.set(COOKIE_NAMES.HIDE_LOGIN_TOOLTIP, 'true', {
      maxAge: COOKIE_MAX_AGES.THIRTY_DAYS_IN_SECONDS,
      path: COOKIE_PATHS.HOTELS,
      sameSite: 'strict',
    });
    dispatch(setUser({ hasDismissedLoginTooltip: true }));
    done();
  },
});

export const dismissWelcomeMessageLogic = createLogic({
  type: dismissWelcomeMessage,
  latest: true,
  async process({ cookies }, dispatch, done) {
    cookies.set(COOKIE_NAMES.HIDE_WELCOME_MESSAGE, 'true', {
      maxAge: COOKIE_MAX_AGES.THIRTY_DAYS_IN_SECONDS,
      path: COOKIE_PATHS.HOTELS,
      sameSite: 'strict',
    });
    dispatch(setUser({ hasDismissedWelcomeMessage: true }));
    done();
  },
});

export const locationChangeLogic = createLogic({
  type: LOCATION_CHANGE,
  latest: true,
  async process({ getState }, dispatch, done) {
    const state = getState();
    const splitTreatments = getTreatments(state);

    const oauthTreatmentEnabled = splitTreatments?.login_service?.treatment === 'oauth';
    const isOauthLoginFlow = OAUTH_ENABLED && oauthTreatmentEnabled;

    // If the user arrived at confirmation from checkout, do not change the booking channel
    if (isOauthLoginFlow) {
      await authenticateUserOAuth(dispatch, getState);
    } else {
      const qffAuth = await getQffAuth();
      const accessToken = qffAuth.getAccessToken();
      if (!accessToken) {
        dispatch(setAuthenticationCompleted());
      }
    }

    done();
  },
});
