import findQhUserId from 'lib/findQhUserId';
import { ACTIVE_SPLITS } from '../../../../config/constants';
import { setTreatments, emitSplitTreatments } from 'store/split/splitActions';
import { getSplitAttributes } from 'store/split/splitSelectors';
import { fetchSplitTreatments } from 'lib/clients/split';

export default async ({ req, store }) => {
  const state = store.getState();

  const qhUserId = findQhUserId(req.cookies);
  const attributes = getSplitAttributes(state);

  try {
    const splitTreatments = await fetchSplitTreatments({ splits: ACTIVE_SPLITS, attributes, qhUserId });
    store.dispatch(setTreatments(splitTreatments));
    store.dispatch(emitSplitTreatments(splitTreatments));
  } catch (e) {
    // do nothing - the client side will poll and pick up the split treatments
  }
};
