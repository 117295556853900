import { captureErrorInSentry, isNonHttpError, parseError } from 'lib/errors';
import noop from 'lodash/noop';
import { createLogic } from 'redux-logic';

export const createAsyncLogic = ({ process, onError = noop, onFinally = noop, ...logicOptions }) => {
  return createLogic({
    ...logicOptions,
    async process(ctx, dispatch, done) {
      try {
        await process(ctx, dispatch, done);
      } catch (error) {
        const parsedError = parseError(error);
        const onErrorReslt = await onError({ ...ctx, error, parsedError }, dispatch, done);
        if (onErrorReslt !== false) {
          if (isNonHttpError(error)) {
            captureErrorInSentry(error);
          }
        }
      } finally {
        await onFinally(ctx, dispatch, done);
        done();
      }
    },
  });
};
