import { createSelector } from '@reduxjs/toolkit';
import { getDestination } from 'store/destination/destinationSelectors';
import { STORE_STATUS } from 'lib/enums/store';

export const getDestinationPageDataLayerVariables = createSelector([getDestination], (destination) => {
  const ready = destination?.status === STORE_STATUS.RESOLVED;

  return {
    ready,
    destinationName: destination?.regionName,
  };
});
