import { PageType } from '@qantasexperiences/analytics';
import { TRACKING_PREFIX } from './brand';

export const pages = [
  {
    name: `${TRACKING_PREFIX} Search Page`,
    type: PageType.SEARCH,
    pattern: /^\/search\/list\/?$/,
    route: 'search-list',
  },
  {
    name: `${TRACKING_PREFIX} Map Page`,
    type: PageType.MAP,
    pattern: /^\/search\/map\/?$/,
    route: 'search-map',
  },
  {
    name: `${TRACKING_PREFIX} Property Page`,
    type: PageType.PROPERTY,
    pattern: /^\/properties\/[0-9]+(\w|-)*\/?$/,
    route: 'property',
  },
  {
    name: `${TRACKING_PREFIX} Property Page`,
    type: PageType.PROPERTY,
    pattern: /^\/properties\/[0-9]+(\w|-)*\/preview?$/,
    route: 'property-preview',
  },
  {
    name: `${TRACKING_PREFIX} Exclusive Offer Page`,
    type: PageType.EXCLUSIVE_OFFER,
    pattern: /^\/properties\/[0-9]+(\w|-)*\/exclusive-offers?$/,
    route: 'exclusive-offer',
  },
  {
    name: `${TRACKING_PREFIX} Booking Page`,
    type: PageType.BOOKING,
    pattern: /^\/checkout\/?$/,
    route: 'checkout',
  },
  {
    name: `${TRACKING_PREFIX} Booking Confirmation Page`,
    type: PageType.BOOKING_CONFIRMATION,
    pattern: /^\/bookings\/([a-z0-9]|-){0,}\/?$/,
    route: 'booking-confirmation',
  },
  {
    name: `${TRACKING_PREFIX} Contact Us Page`,
    type: PageType.CONTACT_US,
    pattern: /^\/contact-us\/?$/,
    route: 'contact-us',
  },
  {
    name: `${TRACKING_PREFIX} Email Us Form Page`,
    type: PageType.EMAIL_US_FORM,
    pattern: /^\/contact-us\/email\/?$/,
    route: 'contact-us-form',
  },
  {
    name: `${TRACKING_PREFIX} FAQs Page`,
    type: PageType.FAQS,
    pattern: /^\/faqs\/?$/,
    route: 'faqs',
  },
  {
    name: `${TRACKING_PREFIX} Destination Page`,
    type: PageType.DESTINATION,
    pattern: /^\/australia(\/?)(.*)$/,
    route: 'destination',
  },
  {
    name: `${TRACKING_PREFIX} Campaigns Page`,
    type: PageType.CAMPAIGNS,
    pattern: /^\/campaigns\/?$/,
    route: 'campaign',
  },
  {
    name: `${TRACKING_PREFIX} Campaigns Preview Page`,
    type: PageType.CAMPAIGNS,
    pattern: /^\/campaigns\/([a-z0-9]|-)\/preview\/?$/,
    route: 'campaign-preview',
  },
  {
    name: `${TRACKING_PREFIX} Auth Page`,
    type: PageType.AUTH,
    pattern: /^\/auth\/?$/,
    route: 'auth',
  },
];

export const statesLookup = {
  'Australian Capital Territory': 'ACT',
  'New South Wales': 'NSW',
  'Northern Territory': 'NT',
  'South Australia': 'SA',
  'Western Australia': 'WA',
  Queensland: 'QLD',
  Tasmania: 'TAS',
  Victoria: 'VIC',
};
