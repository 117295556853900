import { getOAuthState, useOAuthState } from 'lib/oauth/useOAuthState';
import { LSL_AUTH_API_URL, HOTELS_URL, HOTELS_PATH } from 'config';

export const lslClientId = 'jetstar_hotels_ui';

export const getLoginUrl = () => {
  const state = getOAuthState();

  if (typeof state === 'string') {
    const encodedState = encodeURIComponent(state);
    return `${LSL_AUTH_API_URL}?response_type=code&client_id=${lslClientId}&scope=profile&redirect_uri=${HOTELS_URL}/auth&web=true&state=${encodedState}`;
  }

  return;
};

export const useLoginUrl = () => {
  const [state] = useOAuthState();
  const encodedState = encodeURIComponent(state);

  const redirect_uri = typeof window !== 'undefined' ? `${window.location.origin}${HOTELS_PATH}/auth` : `${HOTELS_URL}/auth`;

  return {
    loginUrl: `${LSL_AUTH_API_URL}?response_type=code&client_id=${lslClientId}&scope=profile&redirect_uri=${redirect_uri}&web=true&state=${encodedState}`,
  };
};
