import { createAction } from '@reduxjs/toolkit';

export const clearRecommendedPropertyAvailability = createAction('recommendedProperty/CLEAR_RECOMMENDED_PROPERTY_AVAILABILITY');
export const fetchRecommendedPropertyAvailability = createAction('recommendedProperty/FETCH_RECOMMENDED_PROPERTY_AVAILABILITY');
export const fetchRecommendedPropertyAvailabilityFailure = createAction(
  'recommendedProperty/FETCH_RECOMMENDED_PROPERTY_AVAILABILITY_FAILURE',
);
export const fetchRecommendedPropertyAvailabilitySuccess = createAction(
  'recommendedProperty/FETCH_RECOMMENDED_PROPERTY_AVAILABILITY_SUCCESS',
);
export const setLoading = createAction('recommendedProperty/SET_LOADING');
export const emitRecommendedPropertiesResult = createAction('recommendedProperty/EMIT_RECOMMENDED_PROPERTIES_RESULT');
