import { useSessionStorage } from 'react-use';
import { generateState } from './state';

export const OAUTH_SESSION_KEY = 'oauth_state';

export const getOAuthState: () => string | undefined = () => window?.sessionStorage?.getItem(OAUTH_SESSION_KEY);

export const useOAuthState = () => {
  return useSessionStorage(OAUTH_SESSION_KEY, typeof window !== 'undefined' ? generateState() : '', true);
};
