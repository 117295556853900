import { createReducer } from '@reduxjs/toolkit';
import { setHomePage } from './homePageActions';

const initialState = {
  homePage: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder.addCase(setHomePage, (state, { payload }) => {
    state.homePage = payload;
  }),
);
