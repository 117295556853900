import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Box, Flex } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';

export const NavGroup = styled(Flex)`
  max-width: 1284px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;

  align-items: center;
  justify-content: space-between;

  ${mediaQuery.minWidth.lg} {
    padding-right: 24px;
    padding-left: 24px;
  }
`;

export const PrimaryNav = styled(Flex)`
  gap: 1rem;
`;

export const SecondaryNav = styled(Flex)`
  align-items: center;
  gap: 2rem;
`;

export const HeaderLink = styled('a')<{ active?: boolean }>`
  cursor: pointer;
  text-decoration: none;

  border-style: solid;
  border-width: 0;
  border-color: ${({ active }) => (active ? '#FF5115' : 'transparent')};

  color: #222222;
  font-family: 'Jetstar';
`;

export const PrimaryLink = styled(HeaderLink)`
  padding: 25px 16px 17px;
  border-bottom-width: 8px;

  font-size: ${(props) => themeGet('fontSizes.md')(props)};
  line-height: 22px;

  &:focus,
  &:hover {
    border-color: #ff5115;
  }
`;

export const SecondaryLink = styled(HeaderLink)`
  padding: 13px 0 9px;
  border-bottom-width: 4px;

  font-size: ${(props) => themeGet('fontSizes.sm')(props)};
  line-height: 20px;
  font-weight: bold;
`;

export const NavigationMenuList = styled(NavigationMenu.List)`
  list-style: none;

  padding: 0;
  margin: 0;
`;

export const NavigationMenuTrigger = styled(NavigationMenu.Trigger)`
  cursor: pointer;

  font-size: ${(props) => themeGet('fontSizes.sm')(props)};
  line-height: ${(props) => themeGet('lineHeights.loose')(props)};

  background: transparent;
  margin: 0;
  border: none;
  padding: 0;

  &[data-state='closed'] *:nth-of-type(2n) {
    display: none;
  }

  &[data-state='open'] *:nth-of-type(2n + 1) {
    display: none;
  }
`;

export const NavigationMenuLink = styled(NavigationMenu.Link)`
  display: block;

  outline: none;
  text-decoration: none;
  user-select: none;

  color: #222222;
  font-size: ${(props) => themeGet('fontSizes.sm')(props)};
  line-height: ${(props) => themeGet('lineHeights.loose')(props)};
  font-weight: 500;

  padding: 1rem 1.25rem;

  box-shadow:
    inset 4px 0px 0px ${({ active }) => (active ? '#FF5115' : 'transparent')},
    inset 0px -1px 0px #dedede;

  &:focus,
  &:hover,
  &:active {
    background: #f7f7f7;
  }
`;

export const NavigationMenuContent = styled(NavigationMenu.Content)`
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`;

export const Container = styled(Box)`
  min-height: 72px;
`;

export const Content = styled(Box)`
  position: relative;
  width: 100%;

  &:has(${NavigationMenuContent}) {
    z-index: 1000;
    position: fixed;
    top: 0;
  }
`;

export const List = styled('ul')`
  list-style: none;
  background: white;

  width: 100%;
  max-width: 300px;
  height  100%;
  margin: 0;
  padding: 0;
`;
