import { createSelector } from '@reduxjs/toolkit';
import { createDepositPayAvailabilityMessage } from 'lib/search/createDepositPayAvailabilityMessage';
import { getIsDomestic } from './getIsDomestic';
import { getQueryCheckIn, getQueryClassicRewards } from 'store/router/routerSelectors';
import { getIsLoading } from './searchSelectors';

export const getDepositPayAvailabilityMessage = createSelector(
  getIsLoading,
  getIsDomestic,
  getQueryCheckIn,
  getQueryClassicRewards,
  createDepositPayAvailabilityMessage,
);
