import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchCalendar = async ({ propertyId, startDate, endDate, luxeOnly }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/${propertyId}/availability`,
    method: 'GET',
    params: {
      startDate,
      endDate,
      luxeOnly,
    },
  });

  return response?.data;
};

export const fetchCalendarByOffer = async ({ propertyId, offerId, startDate, endDate, luxeOnly }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/exclusive-offers/${propertyId}/availability/${offerId}`,
    method: 'GET',
    params: {
      startDate,
      endDate,
      luxeOnly,
    },
  });

  return response?.data;
};
