import Visibility from 'lib/browser/visibility';
import { fetchTreatments, emitSplitTreatments, setIsVip } from 'store/split/splitActions';
import { getSplitAttributes, getTreatments } from 'store/split/splitSelectors';
import { SPLIT_BROWSER_REFRESH_RATE_SECS } from 'config';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import cookies from 'js-cookie';
import { COOKIE_NAMES } from 'lib/enums/cookies';

export const useSplit = () => {
  const dispatch = useDispatch();
  const treatments = useSelector(getTreatments);
  const attributes = useSelector(getSplitAttributes);

  useMount(() => {
    dispatch(emitSplitTreatments(treatments));
    if (!attributes.isVip && cookies.get(COOKIE_NAMES.SPLIT_VIP)) {
      dispatch(setIsVip(true));
      dispatch(fetchTreatments());
    }
    Visibility.every(SPLIT_BROWSER_REFRESH_RATE_SECS * 1000, () => {
      dispatch(fetchTreatments());
    });
  });
};

export const initializeSplit = (store) => {
  const treatments = getTreatments(store.getState());
  store.dispatch(emitSplitTreatments(treatments));

  Visibility.every(SPLIT_BROWSER_REFRESH_RATE_SECS * 1000, () => {
    store.dispatch(fetchTreatments());
  });
};
