import omitBy from 'lodash/omitBy';
import map from 'lodash/map';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { updateQuery as searchUpdateQuery } from 'store/search/searchActions';
import { updateQuery as propertyUpdateQuery } from 'store/propertyAvailability/propertyAvailabilityActions';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { getPageFromState } from './helpers';
import { SORTS_BY_CODE } from 'lib/enums/search';
import { propertyTypeEvent, facilitiesEvent, minStarRatingEvent, minTripAdvisorRatingEvent, subRegionEvent } from './searchFilterEvents';
import { format as formatDate } from 'lib/date';
import { getAvailableSubRegions, getSearchQuery } from 'store/search/searchSelectors';

const diff = (last, next) => omitBy(next, (value, key) => last[key] === value);

const emitUpdateQueryInteractionEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  const prevQuery = getSearchQuery(prevState);
  const changeset = diff(prevQuery, action.payload);
  const currency = capitalize(action.payload.payWith || prevQuery.payWith || 'cash');
  const changeEvents = map(changeset, (value, key) => {
    switch (key) {
      case 'checkIn':
        return interactionEvent({ type: 'Date Calendar', value: `Check-in ${formatDate(value)}`, page: page.name });
      case 'checkOut':
        return interactionEvent({ type: 'Date Calendar', value: `Check-out ${formatDate(value)}`, page: page.name });
      case 'adults':
        return interactionEvent({ type: 'Guests Dropdown', value: `Adults ${value}`, page: page.name });
      case 'children':
        return interactionEvent({ type: 'Guests Dropdown', value: `Children ${value}`, page: page.name });
      case 'infants':
        return interactionEvent({ type: 'Guests Dropdown', value: `Infants ${value}`, page: page.name });
      case 'location':
        return interactionEvent({ type: 'Location Search', value: value, page: page.name });
      case 'sortBy':
        return interactionEvent({ type: 'Sort Dropdown', value: `${get(SORTS_BY_CODE[value], 'name')} Selected`, page: page.name });
      case 'payWith':
        return interactionEvent({ type: 'Points And Cash Toggle', value: `${capitalize(value)} Selected`, page: page.name });
      case 'freeCancellation':
        return interactionEvent({ type: 'Free Cancellation', value: `Free Cancellation ${value ? 'Selected' : 'Reset'}`, page: page.name });
      case 'depositPay':
        return interactionEvent({ type: 'Deposit Pay', value: `Deposit Pay ${value ? 'Selected' : 'Reset'}`, page: page.name });
      case 'classicRewards':
        return interactionEvent({ type: 'Classic Rewards', value: `Classic Rewards ${value ? 'Selected' : 'Reset'}`, page: page.name });
      case 'minPrice':
        return interactionEvent({
          type: 'Minimum Price Slider',
          value: value ? `${currency}: ${value}` : `${currency}: Reset`,
          page: page.name,
        });
      case 'maxPrice':
        return interactionEvent({
          type: 'Maximum Price Slider',
          value: value ? `${currency}: ${value}` : `${currency}: Reset`,
          page: page.name,
        });
      case 'minStarRating':
        return minStarRatingEvent(value, page);
      case 'minTripadvisorRating':
        return minTripAdvisorRatingEvent(value, page);
      case 'propertyTypes':
        return propertyTypeEvent(prevQuery.propertyTypes, value, page.name);
      case 'facilities':
        return facilitiesEvent(prevQuery.facilities, value, page.name);
      case 'subRegions': {
        const availableSubRegions = getAvailableSubRegions(prevState);

        return subRegionEvent(availableSubRegions, prevQuery.subRegions, value, page.name);
      }
      default:
        break;
    }
  });

  return changeEvents.length ? changeEvents : null;
};

export default {
  [searchUpdateQuery]: emitUpdateQueryInteractionEvent,
  [propertyUpdateQuery]: emitUpdateQueryInteractionEvent,
};
