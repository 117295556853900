import React, { Fragment, useCallback } from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Flex, Text, Box, Container, Icon } from '@qga/roo-ui/components';
import { themeGet } from 'styled-system';
import { useSelector } from 'react-redux';
import { getPathName } from 'store/router/routerSelectors';
import { qantasBusinessRewards, qantasQff } from '@qga/roo-ui/logos';
import { DisclaimerItemLogo, DisclaimerText, DisclaimerLink } from 'components/Footer/DisclaimerItems';
import { useDataLayer } from 'hooks/useDataLayer';
import { getCampaignTermsAndConditions } from 'store/campaign/campaignSelectors';
import TravelInsuranceDisclaimer from './TravelInsuranceDisclaimer';
import {
  TRIP_ADVISOR_IMG_BRANDING_URL,
  QCOM_TERMS_AND_CONDITIONS_URL,
  POINTS_EARN_ENABLED,
  QFF_ACCOUNT_MANAGEMENT,
  ACCOUNT_MANAGEMENT_TYPES,
  VPP_TERMS_CONDITIONS,
  TRAVEL_INSURANCE_CROSS_SELL_ENABLED,
  CAMPAIGNS_ENABLED,
  TERMS_OF_USE_URL,
  AIRBNB_TAB_URL,
} from 'config';
import Markup from 'components/Markup';
import TreatmentToggle from 'components/TreatmentToggle';

const TripAdvisorLogo = styled.img`
  width: ${rem('120px')};
  height: ${rem('24px')};
  margin-left: ${themeGet('space.2')};
  margin-bottom: -${themeGet('space.1')};
`;

const savingDisclaimer =
  "~ Saving is off the hotel's generally available rate for the same property, room type, days, inclusions and conditions.";

const QFFDisclaimer =
  '±Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through jetstar.com/hotels, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking value with an Accepted Payment Card (including VISA, Mastercard or American Express).';

const airbnbPointsDisclaimer =
  '++ 500 bonus Qantas Points will be awarded to Qantas Frequent Flyer members who make their first Airbnb booking. Qantas Frequent Flyer members will earn 1 Qantas Point per A$1 spent for all Airbnb stays booked through';

const Disclaimer = () => {
  const { emitInteractionEvent } = useDataLayer();

  const handleOnClickAirbnbLink = useCallback(() => {
    emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' });
  }, [emitInteractionEvent]);

  const pathName = useSelector(getPathName);
  const isConfirmationPage = pathName?.startsWith('/bookings');

  return (
    <Container pt={[8, 8, 15]} pb={[10, 10, 17]} gutter={[4, 6]} maxWidth={1284} bg="white" data-print-style="hidden">
      <Flex flexDirection="column" style={{ gap: '2.25rem' }}>
        <Text display="block" fontSize="base" fontWeight="bold" style={{ fontFamily: 'Jetstar' }}>
          Important information
        </Text>
        <Flex flexDirection={['column', 'column', 'row']} alignItems="flex-start" style={{ gap: '1rem' }}>
          <DisclaimerItemLogo
            src={qantasQff}
            alt="Qantas QFF Logo"
            role="img"
            aria-label="Qantas QFF Logo"
            style={{ height: 26, marginRight: 0 }}
          />
          <Flex flexDirection="column" style={{ gap: '2rem' }}>
            {POINTS_EARN_ENABLED && <QFFEarnDisclaimers />}
            {QFF_ACCOUNT_MANAGEMENT === ACCOUNT_MANAGEMENT_TYPES.CHECKOUT_ONLY && <VPPDisclaimer />}
            {!POINTS_EARN_ENABLED && (
              <Flex flexDirection="column" style={{ gap: '2rem' }}>
                <Flex data-testid="qff-disclaimer">
                  <DisclaimerText>
                    {QFFDisclaimer}&nbsp;{' '}
                    <DisclaimerLink data-testid="QFFDisclaimerT&Cs" href={TERMS_OF_USE_URL}>
                      View full terms and conditions here.
                    </DisclaimerLink>
                  </DisclaimerText>
                </Flex>
                <Flex data-testid="saving-disclaimer">
                  <DisclaimerText>{savingDisclaimer}&nbsp;</DisclaimerText>
                </Flex>
                <DisclaimerText data-testid="airbnb-points-disclaimer">
                  {airbnbPointsDisclaimer}&nbsp;
                  <DisclaimerLink href={AIRBNB_TAB_URL} onClick={handleOnClickAirbnbLink}>
                    jetstar.com/au/en/hotels
                  </DisclaimerLink>
                  .
                </DisclaimerText>
              </Flex>
            )}
            <TreatmentToggle split="travel_insurance_cross_sell_banner" treatment="on">
              {isConfirmationPage && <Flex>{TRAVEL_INSURANCE_CROSS_SELL_ENABLED && <TravelInsuranceDisclaimer />}</Flex>}
            </TreatmentToggle>
            <Flex>
              <DisclaimerText data-testid="description-ratings-disclaimer-text">
                Descriptions and ratings featured are based on information provided by hotels and/or other service providers, distinguished
                accordingly. Ratings are either self-assigned by the service provider or obtained through Expedia (displayed as circles{' '}
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
                ), or by independent third parties (displayed as stars <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
                <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />) Any facilities shown as included are subject to change by
                the service provider. Jetstar does not guarantee any particular standard or class of accommodation or other service provided
                by a third party.
              </DisclaimerText>
            </Flex>
            <Flex>
              <DisclaimerText>
                † Includes fee payable in local currency direct to hotel. Prices in AUD are approx and based on today&#39;s exchange rate.
              </DisclaimerText>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <DisclaimerText display="flex">
            Hotel ratings and reviews provided by
            <TripAdvisorLogo src={TRIP_ADVISOR_IMG_BRANDING_URL} alt="Trip-Advisor Logo" role="img" aria-label="Trip-Advisor Logo" />
          </DisclaimerText>
        </Flex>
      </Flex>
    </Container>
  );
};

const QFFEarnDisclaimers = () => {
  const { emitInteractionEvent } = useDataLayer();
  const campaignTermsAndConditions = useSelector(getCampaignTermsAndConditions);
  const defaultDisclaimerTermsAndConditions =
    'You must be a Qantas Frequent Flyer member to earn and redeem points. Membership and points are subject to the Qantas Frequent Flyer program terms and conditions.';
  const defaultTermsAndConditions =
    'Qantas Frequent Flyer members will earn 3 Qantas Points per A$1 value unless otherwise specified, for hotel stays booked through qantas.com/hotels, except Classic Hotel Rewards and Airbnb bookings. Points Club members will earn 25% more Qantas Points, and Points Club Plus members will earn 50% more Qantas Points. Qantas Points will be credited to your account at least 8 weeks after check-out. Qantas Points may be earned by the member in whose name the booking is made. Members will not be able to earn points on additional charges paid to the accommodation provider for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable).';
  const pointsPlusPayTermsAndConditions =
    '* Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through qantas.com/hotels or holiday packages through qantas.com/holidays, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking value with an Accepted Payment Card (including VISA, MasterCard or American Express). Points Plus Pay is not available for Classic Hotel Rewards.';
  const statusCreditTermsAndConditions =
    '^From 26 December 2021 to 31 December 2021, Qantas Frequent Flyer members will earn 10 bonus Status Credits per night when booking an eligible hotel stay at https://www.qantas.com/hotels/exclusive-offers or Holiday Package at https://www.qantas.com/holidays/deals/status-credits. The number of Status Credits earned varies depending on the length of stay and is limited to a maximum of 100 Status Credits per booking, and a maximum of 100 Status Credits per member over the whole campaign. See https://www.qantas.com/hotels/exclusive-offers for the full list of eligible hotels and https://www.qantas.com/holidays/deals/status-credits for the full list of eligible Holiday Packages. Offer is valid on bookings made between 26 December 2021 and 31 December 2021 for select travel periods up to 31 December 2022. Offer may be extended, changed or cancelled without notice. Status Credits will not be earned on cancelled or refunded bookings. Status Credits cannot be split between two or more members occupying the same room. To earn Status Credits, quote your Qantas Frequent Flyer membership number when booking. Members will not be able to earn Status Credits on additional charges paid to the accommodation provider for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Status Credits will be credited to your account at least 4 weeks after check-out. Bonus Status Credits will not contribute towards any Loyalty Bonuses, or any Platinum or Platinum One Bonus Rewards. Status Credits are not transferable and will be governed by Qantas Frequent Flyer program terms and conditions.';
  const termsAndConditions = campaignTermsAndConditions ? defaultTermsAndConditions : `^ ${defaultTermsAndConditions}`;
  const triplePointsCampaign =
    'Qantas Frequent Flyer members will earn 9 Qantas Points per A$1 value on all hotels booked in North America, Canada, Hawaii and Mexico booked through qantas.com/hotels or qantas.com/luxuryhotels, except Classic Hotel Rewards and Airbnb, from 12:01am (AEST) 7 July 2022 until 11:59pm (AEST) 11 July 2022 for stays before 30 June, 2023. Points Club members will earn 25% more Qantas Points, and Points Club Plus members will earn 50% more Qantas Points. Offer excludes Holiday Packages. Qantas Points will not be earned on cancelled or refunded bookings. Qantas Points cannot be split between two or more members occupying the same room. To earn Qantas Points, quote your Qantas Frequent Flyer membership number when booking. Members will not be able to redeem points for, or earn points on additional charges paid to the accommodation provider for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Qantas Points will be credited to your account at least 8 weeks after check-out.';
  const handleOnClickPointsPlusPayLink = useCallback(() => {
    emitInteractionEvent({ type: 'Points Plus Pay Conditions', value: 'View Full Terms and Conditions Link Selected' });
  }, [emitInteractionEvent]);
  const handleOnClickBusinessRewardLink = useCallback(() => {
    emitInteractionEvent({ type: 'Business Rewards Conditions', value: 'Conditions Apply Link Selected' });
  }, [emitInteractionEvent]);
  const handleOnClickAirbnbLink = useCallback(() => {
    emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' });
  }, [emitInteractionEvent]);
  return (
    <Fragment>
      <Flex flexDirection={['column', 'row']} alignItems="flex-start">
        <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" />
        <DisclaimerText data-testid="points-disclaimer-text">
          {campaignTermsAndConditions && <Markup content={`^ ${campaignTermsAndConditions} &nbsp;`} />}
          <TreatmentToggle split="status_credit_terms" treatment="on">
            <Box mt={2} data-testid="status_credit_terms">
              {statusCreditTermsAndConditions} &nbsp;
            </Box>
          </TreatmentToggle>
          <Box mt={2} data-testid="disclaimer-terms-and-conditions">
            {defaultDisclaimerTermsAndConditions}&nbsp;
          </Box>
          <Box mt={2} data-testid="terms-and-conditions">
            {termsAndConditions} &nbsp;
          </Box>
          {CAMPAIGNS_ENABLED && (
            <TreatmentToggle split="triple_points_campaign_messaging" treatment="on">
              <Flex mt={2} data-testid="triple_points_campaign_messaging" flexDirection="column">
                <Text>{triplePointsCampaign} &nbsp;</Text>
              </Flex>
            </TreatmentToggle>
          )}
          <Box mt={2} data-testid="points-plus-pay-t&c">
            {pointsPlusPayTermsAndConditions} &nbsp;
            <DisclaimerLink
              data-testid="pointsPlusPayViewFullTermsAndConditionsLink"
              href={QCOM_TERMS_AND_CONDITIONS_URL}
              onClick={handleOnClickPointsPlusPayLink}
            >
              View full terms and conditions here
            </DisclaimerLink>
          </Box>
          <TreatmentToggle split="vpp" treatment="on">
            <Box mt={2} data-testid="vpp-terms-and-conditions">
              {VPP_TERMS_CONDITIONS} &nbsp;
            </Box>
          </TreatmentToggle>
          <Box mt={2} data-testid="saving-disclaimer">
            {savingDisclaimer}&nbsp;
          </Box>
          <Box data-testid="airbnb-points-disclaimer">
            {airbnbPointsDisclaimer}&nbsp;
            <DisclaimerLink href={AIRBNB_TAB_URL} onClick={handleOnClickAirbnbLink}>
              jetstar.com/au/en/hotels
            </DisclaimerLink>
            .
          </Box>
        </DisclaimerText>
      </Flex>
      <Flex flexDirection={['column', 'row']} alignItems="flex-start">
        <DisclaimerItemLogo
          src={qantasBusinessRewards}
          alt="Qantas Business Reward Logo"
          role="img"
          aria-label="Qantas Business Reward Logo"
        />
        <DisclaimerText>
          ** Businesses earn 1 Qantas Point per AU$1 spent on eligible Qantas Hotels worldwide. Eligible Qantas hotels excludes Classic
          Hotel Rewards. A business must be a Qantas Business Rewards Member to earn Qantas Points for your business. Qantas Points are not
          earned on any amounts payable directly to the hotel. &nbsp;
          <DisclaimerLink
            data-testid="businessRewardConditionApplyLink"
            href={QCOM_TERMS_AND_CONDITIONS_URL}
            onClick={handleOnClickBusinessRewardLink}
          >
            Conditions apply
          </DisclaimerLink>
        </DisclaimerText>
      </Flex>
    </Fragment>
  );
};
const VPPDisclaimer = () => (
  <TreatmentToggle split="vpp" treatment="on">
    <Flex flexDirection={['column', 'row']} alignItems="flex-start">
      {/* <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" /> */}
      <DisclaimerText data-testid="points-disclaimer-text">
        <Box data-testid="vpp-terms-and-conditions">{VPP_TERMS_CONDITIONS} &nbsp;</Box>
      </DisclaimerText>
    </Flex>
  </TreatmentToggle>
);
export default Disclaimer;
