import { brandColors, colors } from './colors';
import { fontSizes } from './fontSizes';
import { theme as baseTheme } from '@qga/roo-ui';

export const fontFamily = 'Helvetica Neue, sans-serif';

const baseHeadingStyle = {
  fontFamily: fontFamily,
  fontWeight: baseTheme.fontWeights.bold,
  color: colors.greys.mineShaft,
};

export const textStyles = {
  h1: {
    ...baseTheme.textStyles.h1,
    ...baseHeadingStyle,
    fontSize: fontSizes.xl,
  },
  h2: {
    ...baseTheme.textStyles.h2,
    ...baseHeadingStyle,
  },
  h3: {
    ...baseHeadingStyle,
    ...baseTheme.textStyles.h3,
  },
  h4: {
    ...baseHeadingStyle,
    ...baseTheme.textStyles.h4,
  },
  h5: {
    ...baseHeadingStyle,
    ...baseTheme.textStyles.h5,
  },
  h6: {
    ...baseHeadingStyle,
    ...baseTheme.textStyles.h6,
  },
  currency: {
    fontFamily: 'Jetstar',
    color: brandColors.orange,
  },
  link: {
    ...baseTheme.textStyles.text,
    color: colors.ui.link,
    textDecoration: 'none',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  viewMoreLink: {
    fontWeight: 'bold',
  },
  greyLink: {
    ...baseTheme.textStyles.text,
    color: colors.greys.charcoal,
    textDecoration: 'underline',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  button: {
    color: colors.ui.link,
    fontWeight: baseTheme.fontWeights.bold,
    textTransform: 'uppercase',
    textDecoration: 'none',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  cancel: {
    ...baseTheme.textStyles.text,
    color: colors.greys.steel,
    textDecoration: 'none',
    '&:hover': {
      color: colors.ui.linkHover,
    },
    '&:disabled': {
      color: colors.greys.steel,
    },
  },
  dealsHeader: {
    fontWeight: baseTheme.fontWeights.bold,
  },
  modalLabel: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: baseTheme.fontWeights.bold,
  },
};
