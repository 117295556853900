import get from 'lodash/get';
import { getIpAddress, getBrowser, getIsBot, getDeviceType } from 'store/userEnvironment/userEnvironmentSelectors';

export const getTreatments = (state) => get(state, 'split.treatments', {});

//ensure that the same attributes are passed to split in the split proxy app
export const getSplitAttributes = (state) => ({
  ipAddress: getIpAddress(state),
  isVip: get(state, 'split.isVip', false),
  userAgent: getBrowser(state).userAgent,
  isBot: getIsBot(state),
  deviceType: getDeviceType(state),
});
