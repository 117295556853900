import { theme as baseTheme } from '@qga/roo-ui';
import { fontSizes } from './fontSizes';
import { brandColors, colors } from './colors';
import { rem } from 'polished';

export const buttons = {
  primary: {
    letterSpacing: baseTheme.letterSpacings.normal,
    textTransform: 'none',
    color: brandColors.white,
    backgroundColor: brandColors.orange,
    borderRadius: rem(40),
    '&&:focus': {
      backgroundColor: brandColors.sunset,
      color: brandColors.white,
    },
  },
  secondary: {
    letterSpacing: baseTheme.letterSpacings.normal,
    textTransform: 'none',
    color: brandColors.white,
    backgroundColor: brandColors.blue,
    '&&:hover:not(:disabled), &&:focus': {
      backgroundColor: brandColors.cerulean,
    },
  },
  inverted: {
    color: colors.brand.primary,
    backgroundColor: colors.white,
    textTransform: 'none',
  },
  menuButton: {
    color: colors.greys.charcoal,
    backgroundColor: colors.white,
    border: baseTheme.borders[1],
    borderColor: colors.greys.alto,
    fontSize: fontSizes.base,
    fontWeight: baseTheme.fontWeights.normal,
    lineHeight: baseTheme.lineHeights.tight,
    letterSpacing: 'normal',
    textTransform: 'none',
    '&&:hover:not(:disabled)': {
      borderColor: colors.greys.alto,
      backgroundColor: colors.white,
    },
    '&:active': {
      borderColor: colors.brand.secondary,
      backgroundColor: colors.ui.infoBackground,
    },
    '&:focus': {
      borderColor: colors.brand.secondary,
      backgroundColor: colors.ui.infoBackground,
    },
  },
  mobilePillButton: {
    border: 0,
    padding: 0,
    backgroundColor: 'transparent',
    textTransform: 'none',
    letterSpacing: 'normal',
    color: colors.white,
    minWidth: '126px',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  dealsFilterButton: {
    fontWeight: baseTheme.fontWeights.bold,
    border: baseTheme.borders[2],
    borderColor: colors.greys.alto,
    borderRadius: baseTheme.radii.rounded,
    padding: '0.4rem 0.45rem',
  },
  dealsFilterButtonFocus: {
    shadow: `inset 0px 0px 0px 1px ${brandColors.blue}`,
  },
  dealsOptionButton: {
    fontWeight: baseTheme.fontWeights.bold,
    borderColor: colors.greys.alto,
    borderWidth: '2px',
    '&:focus': {
      shadow: `inset 0px 0px 0px 1px ${brandColors.blue}`,
    },
  },
  dealsOptionButtonActive: {
    color: brandColors.black,
    backgroundColor: brandColors.brightGrey,
    borderColor: brandColors.black,
  },
  searchBannerCta: {
    color: colors.ui.link,
    border: `1px solid ${colors.ui.link}`,
    borderRadius: baseTheme.radii.rounded,
    padding: `0 ${baseTheme.space[6]}`,
    '&:hover': {
      color: colors.ui.linkHover,
      border: `1px solid ${colors.ui.linkHover}`,
    },
    '&:focus': {
      shadow: `inset 0px 0px 0px 1px ${brandColors.blue}`,
    },
  },
};

export default buttons;
