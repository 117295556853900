import { rem } from 'polished';

export const BREAK_POINTS_PX = [768, 1100, 1280];

export const breakpoints = BREAK_POINTS_PX.map((bp) => `${bp}px`);

export const mediaQueries = BREAK_POINTS_PX.map((bp) => `@media screen and (min-width: ${bp}px)`);

export const maxWidths = {
  default: rem('1260px'),
  narrow: rem('650px'),
  sidebar: rem('280px'),
};

export const uiStructure = {
  phoneMenuDrawerCloseWidth: rem('64px'),
  mediumScreenSidebar: rem('400px'),
  tooltipWidth: rem('540px'),
};

export const imageSizes = {
  mapSearchResult: {
    height: '100%',
    width: rem('135px'),
  },
  mapSearchResultPhone: {
    height: '100%',
    width: rem('96px'),
  },
  searchThumbnail: {
    width: [rem('135px'), rem('242px')],
    height: ['100%', rem('225px')],
  },
  legacyRoomTypeThumbnail: {
    width: ['100%', rem('200px')],
    height: rem('140px'),
  },
  roomTypeThumbnail: {
    width: ['100%', rem('278px')],
    height: rem('180px'),
  },
  propertyDescription: {
    width: ['100%', rem('720px'), rem('812px')],
    height: [rem('195px'), rem('460px')],
  },
};
