import fecha from 'fecha';
import { LocalDate } from './localDate';

export const toLocalDate = (date) => {
  if (date instanceof LocalDate) {
    return date;
  }
  return LocalDate.fromString(date);
};

export const format = (date, formatting) => {
  const d = toLocalDate(date);
  const jsDate = new Date(d.year, d.month - 1, d.day);
  // eslint-disable-next-line import/no-named-as-default-member
  return fecha.format(jsDate, formatting);
};
