import { setIsMobileApp } from 'store/ui/uiActions';

const loadClientType = async ({ store, query }) => {
  const clientType = query?.client;

  store.dispatch(setIsMobileApp(clientType === 'qta'));
  // Update the url -without- the 'client' query string
};

export default loadClientType;
