import { createSelector } from '@reduxjs/toolkit';
import { filter, reduce } from 'lodash';
import { getAvailableRoomTypes } from './propertyAvailabilitySelectors';

export const getGa4Pricing = createSelector(getAvailableRoomTypes, (roomTypes) => {
  const ga4RoomOffers = reduce(
    roomTypes,
    (accum, room) => {
      const mappedRooms = () => {
        const roomPromotionOffers = filter(room?.offers, 'promotion.name');
        const roomOffers = reduce(
          roomPromotionOffers,
          (accum, offer) => {
            const roomOffer = {
              totalPayable: Number(offer?.charges?.total?.amount) || null,
              totalBeforeDiscount: Number(offer?.charges?.totalBeforeDiscount?.amount) || null,
              promotions: offer?.promotion?.name,
            };
            return [...accum, roomOffer];
          },
          [],
        );

        return roomOffers;
      };
      return [...accum, ...mappedRooms()];
    },
    [],
  );

  const offerCount = ga4RoomOffers?.length;

  const promotions = reduce(
    ga4RoomOffers,
    (accum, offer) => {
      return [...accum, offer.promotions];
    },
    [],
  );

  const averagePrice = Math.floor(
    reduce(
      ga4RoomOffers,
      (accum, offer) => {
        return accum + offer?.totalPayable;
      },
      0,
    ) / offerCount,
  );

  const averagePriceBeforeDiscount = Math.floor(
    reduce(
      ga4RoomOffers,
      (accum, offer) => {
        return accum + offer?.totalBeforeDiscount;
      },
      0,
    ) / offerCount,
  );

  return {
    averagePrice: averagePrice,
    averagePriceBeforeDiscount: averagePriceBeforeDiscount,
    offerCount: offerCount,
    promotions: promotions,
  };
});
