import * as actions from 'store/deal/dealActions';
import { fetchByDealType, fetchByLocation } from 'lib/clients/fetchDeals';
import { createAsyncLogic } from 'lib/logic';
import { STORE_STATUS } from 'lib/enums/store';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { getSearchQuery } from 'store/search/searchSelectors';
import { getDealType, getRegion } from '../dealSelectors';

export const fetchDealsLogic = createAsyncLogic({
  type: actions.fetchDeals,
  latest: true,
  async process({ getState, action }, dispatch) {
    dispatch(actions.setStatus(STORE_STATUS.PENDING));

    const { limit = 4 } = action.payload || {};
    const state = getState();
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const query = getSearchQuery(state);
    const region = getRegion(state);
    const dealType = getDealType(state);
    const code = dealType.code === 'all_deals' ? undefined : dealType.code;

    const handler = !dealType || dealType?.code === 'all_deals' ? fetchByLocation : fetchByDealType;

    const { results, meta } = await handler({
      ...query,
      adults: query.adults,
      dealType: code,
      location: region?.fullName,
      limit,
      accessToken,
      flightBookerToken,
      qhUserId,
    });

    dispatch(actions.setDeals({ results, meta }));
    dispatch(actions.setStatus(STORE_STATUS.RESOLVED));
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.setError(parsedError));
    dispatch(actions.setStatus(STORE_STATUS.FAILED));
  },
});
