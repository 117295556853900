const getImageSrcSet = (property) =>
  property.mainImage ? `${property?.mainImage?.urlLarge} 2x, ${property?.mainImage?.urlMedium} 1x` : undefined;
const getImageAltTag = (property) => `${property.name} - ${property?.address?.suburb} - ${property?.address?.country}`;

const getCustomerRating = (property) => (property.customerRatings || []).find((rating) => rating.source === 'trip_advisor') || {};

const buildPropertyCardsData = ({ results, imageSrcSize = 'urlSmall' }) =>
  results.map(({ property, offer }) => {
    const { charges, pointsEarned } = offer.pricing?.[0] || offer;

    return {
      id: property.id,
      propertyName: property.name,
      imageSrc: property?.mainImage?.[imageSrcSize],
      imageSrcSet: getImageSrcSet(property),
      imageAltTag: getImageAltTag(property),
      total: charges.total,
      hasDiscount: !!Number(charges.totalBeforeDiscount.amount) && charges.totalBeforeDiscount.amount !== charges.total.amount,
      totalBeforeDiscount: charges.totalBeforeDiscount,
      promotionName: offer?.promotion?.name,
      customerRating: getCustomerRating(property),
      rating: property.rating,
      ratingType: property.ratingType,
      pointsEarned,
      isNonRefundable: !!(offer.cancellationPolicy.isNonrefundable || offer.cancellationPolicy.isNonRefundable),
      offerId: offer.id,
      offerType: offer.type,
      latitude: property.latitude,
      longitude: property.longitude,
      country: property?.address?.country,
    };
  });

export default buildPropertyCardsData;
