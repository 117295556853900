import get from 'lodash/get';
import { POINTS_CLUB_TIERS_MAPPING } from 'lib/enums/pointsClub';
import Decimal from 'decimal.js';
import { createSelector } from '@reduxjs/toolkit';

export const QFF_TITLE_MAPPING = {
  MR: 'Mr',
  MRS: 'Mrs',
  MS: 'Ms',
  MISS: 'Miss',
  DR: 'Dr',
  CAPT: 'Capt',
  REV: 'Rev',
};

export const getMemberId = (state) => get(state, 'user.authentication.memberId');
export const getTitle = (state) => {
  const title = get(state, 'user.authentication.title');
  return QFF_TITLE_MAPPING[title] || title;
};
export const getFirstName = (state) => get(state, 'user.authentication.firstName');
export const getLastName = (state) => get(state, 'user.authentication.lastName');
export const getEmailAddress = (state) => get(state, 'user.authentication.emailAddress');
export const getPhoneNumber = (state) => get(state, 'user.authentication.phoneNumber');

export const getMembershipTier = (state) => get(state, 'user.authentication.membershipTier');
export const getAccessToken = (state) => get(state, 'user.authentication.accessToken');
export const getPointsClubLevel = (state) => {
  const pointsClubLevel = get(state, 'user.authentication.pointsClubLevel');
  return POINTS_CLUB_TIERS_MAPPING[pointsClubLevel] || null;
};

const getRawPointsBalance = (state) => get(state, 'user.authentication.pointsBalance');

export const getPointsBalance = createSelector(getRawPointsBalance, (rawPointsBalance) => new Decimal(rawPointsBalance || 0));

export const getFlightBookerToken = (state) => get(state, 'user.flightBookerToken');
export const getQhUserId = (state) => get(state, 'user.qhUserId');

export const getIsAuthenticated = (state) => !!getAccessToken(state);
export const getIsAuthenticatedOAuth = (state) => !!getMemberId(state);
export const getIsFlightBooker = (state) => !!getFlightBookerToken(state);

export const getHasDismissedSearchMessaging = (state) => get(state, 'user.hasDismissedSearchMessaging');
export const getHasDismissedLoginTooltip = (state) => get(state, 'user.hasDismissedLoginTooltip');
export const getHasDismissedWelcomeMessage = (state) => get(state, 'user.hasDismissedWelcomeMessage');

export const getHasAuthenticationCompleted = (state) => get(state, 'user.hasAuthenticationCompleted');
