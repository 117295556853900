import * as React from 'react';
import { useSelector } from 'react-redux';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import omit from 'lodash/omit';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { jetstar } from '@qga/roo-ui/logos';
import { Box, Hide, Text } from '@qga/roo-ui/components';
import { getQueryParams } from 'store/router/routerSelectors';
import { getProperty } from 'store/property/propertySelectors';
import { useDataLayer } from 'hooks/useDataLayer';
import stringifyQueryValues from 'lib/search/stringifyQueryValues';
import {
  NavGroup,
  PrimaryNav,
  SecondaryNav,
  PrimaryLink,
  SecondaryLink,
  NavigationMenuTrigger,
  NavigationMenuList,
  NavigationMenuLink,
  NavigationMenuContent,
  Container,
  Content,
  List,
} from './UpdatedHeader.style';
import useMenuClickEvent from 'hooks/useMenuClickEvent';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { HOTELS_URL } from 'config';

const primaryLinks = [
  { href: 'https://www.jetstar.com/au/en/flights', label: 'Flights' },
  { href: 'https://www.jetstar.com/au/en/holidays', label: 'Holidays' },
  { href: '/', active: true, label: 'Hotels' },
  { href: 'https://www.jetstar.com/au/en/car-hire', label: 'Car Hire' },
];

const secondaryLinks = [
  { href: '/', label: 'Hotels' },
  { href: '/deals', label: 'Deals' },
  { href: '/?searchType=airbnb', label: 'Airbnb' },
  { href: '/faqs', label: 'FAQs' },
  { href: '/contact-us', label: 'Contact us' },
];

const UpdatedHeader = () => {
  const router = useRouter();
  const property = useSelector(getProperty);
  const query = useSelector(getQueryParams);
  const content = React.useRef();
  const [value, setValue] = React.useState('');

  const { emitInteractionEvent } = useDataLayer();
  const { menuClickEvent } = useMenuClickEvent();
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobile = isLessThanBreakpoint(0);

  if (!query?.location && property) query.location = property.regionFullName;
  const omitParams = ['searchType', 'sortBy', 'offerId', 'roomTypeId', 'excludeParams'];
  const queryString = stringifyQueryValues(omit(query, omitParams));

  const resizeHandler = React.useCallback(() => {
    if (window.innerWidth > 1100) clearAllBodyScrollLocks();
    else if (value) disableBodyScroll(content.current);
  }, [value]);

  React.useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  React.useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const handleLogoClick = (href: string) => {
    menuClickEvent({ itemText: 'Header Logo selected', url: href });
  };

  const handleClick = (label: string, href: string) => {
    emitInteractionEvent({ type: `${label} tab click`, value: `${label} tab clicked` });
    menuClickEvent({ itemText: isMobile ? `Mobile Header ${label} selected` : `Desktop Header ${label} selected`, url: href });
  };

  return (
    <Container ref={content}>
      <Content>
        <Box bg="white" boxShadow="inset 0px -1px 0px #dedede">
          <NavGroup height="72px">
            <a href={HOTELS_URL} target="_blank" rel="noopener noreferrer" onClick={() => handleLogoClick(HOTELS_URL)}>
              <img src={jetstar} alt="Jetstar logo" />
            </a>
            <Hide xs sm>
              <PrimaryNav>
                {primaryLinks.map(({ href, label, active }) => {
                  const updatedHref = `/?${router.isReady ? queryString : ''}`;
                  const isHotels = label === 'Hotels';
                  return (
                    <NextLink key={href} href={isHotels ? updatedHref : href} passHref>
                      <PrimaryLink active={active}>{label}</PrimaryLink>
                    </NextLink>
                  );
                })}
              </PrimaryNav>
            </Hide>
            <Hide md lg>
              <NavigationMenu.Root
                orientation="vertical"
                onValueChange={(value) => {
                  if (value) disableBodyScroll(content.current);
                  else enableBodyScroll(content.current);
                  setValue(value);
                }}
              >
                <NavigationMenuList>
                  <NavigationMenu.Item>
                    <NavigationMenuTrigger
                      onPointerMove={(event) => event.preventDefault()}
                      onPointerLeave={(event) => event.preventDefault()}
                    >
                      <span>Menu</span>
                      <Text fontSize="1.125rem" fontWeight={700} color="#FF5115">
                        &#x2715;
                      </Text>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent
                      onPointerEnter={(event) => event.preventDefault()}
                      onPointerLeave={(event) => event.preventDefault()}
                    >
                      <List>
                        {primaryLinks.slice(0, -1).map(({ href, label, active }) => {
                          const isHotels = label === 'Hotels';
                          const updatedHref = `/?${queryString}`;
                          return (
                            <li key={href}>
                              {active ? (
                                <NextLink href={isHotels ? updatedHref : href} passHref>
                                  <NavigationMenuLink active={active}>{label}</NavigationMenuLink>
                                </NextLink>
                              ) : (
                                <NavigationMenuLink key={href} href={href}>
                                  {label}
                                </NavigationMenuLink>
                              )}
                            </li>
                          );
                        })}
                        {secondaryLinks.slice(1).map(({ href, label }) => (
                          <li key={href}>
                            <NextLink href={href} passHref>
                              <NavigationMenuLink
                                data-testid={`${label}-tab`}
                                style={{ fontWeight: 400, paddingLeft: '2.5rem' }}
                                onClick={() => handleClick(label, href)}
                              >
                                {label}
                              </NavigationMenuLink>
                            </NextLink>
                          </li>
                        ))}
                        {primaryLinks.slice(-1).map(({ href, label }) => (
                          <li key={href}>
                            <NavigationMenuLink href={href}>{label}</NavigationMenuLink>
                          </li>
                        ))}
                      </List>
                    </NavigationMenuContent>
                  </NavigationMenu.Item>
                </NavigationMenuList>
              </NavigationMenu.Root>
            </Hide>
          </NavGroup>
        </Box>
      </Content>
      <Hide xs sm>
        <NavGroup height="60px">
          <SecondaryNav>
            {secondaryLinks.slice(0, -1).map(({ href, label }, i) => {
              const isHotels = label === 'Hotels';
              const updatedHref = `/?${queryString}`;
              return (
                <NextLink key={href} href={isHotels ? updatedHref : href} passHref>
                  <SecondaryLink
                    active={i === 0 ? href === router.pathname : router.pathname.startsWith(href)}
                    onClick={() => handleClick(label, href)}
                  >
                    {label}
                  </SecondaryLink>
                </NextLink>
              );
            })}
          </SecondaryNav>
          <SecondaryNav mr="1rem">
            {secondaryLinks.slice(-1).map(({ href, label }) => (
              <NextLink key={href} href={href} passHref>
                <SecondaryLink active={href === router.pathname} onClick={() => handleClick(label, href)}>
                  {label}
                </SecondaryLink>
              </NextLink>
            ))}
          </SecondaryNav>
        </NavGroup>
      </Hide>
    </Container>
  );
};

export default UpdatedHeader;
