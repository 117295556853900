import { createLogic } from 'redux-logic';
import { differenceInDays } from 'date-fns';
import { trackBooking } from 'store/booking/bookingActions';
import { trackEvent } from 'store/split/splitActions';
import * as recommendations from 'lib/analytics/recommendations';

// note that daysToCheckIn is comparing a date time of the server with a
// local check in timezone of the property so not 100% accurate
const splitPropertiesForBooking = (booking) => {
  const isClassicReward = booking?.reservation?.offer?.type === 'classic';
  const isDepositPay = booking?.paymentSchedule?.length > 0;
  const isFreeCancellation = booking?.reservation?.offer?.cancellationPolicy?.isNonRefundable === false;
  const isFilterableOffer = isClassicReward || isDepositPay || isFreeCancellation;
  const wasInteractedWithInPropertyRecommendations = !!recommendations.getInteraction(booking?.reservation?.property?.id);
  const wasShowInPropertyRecommendations = !!recommendations.getImpression(booking?.reservation?.property?.id);

  return {
    isDepositPay,
    isFilterableOffer,
    wasShowInPropertyRecommendations,
    wasInteractedWithInPropertyRecommendations,
    daysToCheckIn: differenceInDays(new Date(booking?.reservation?.checkIn), new Date(booking?.createdAt)),
    numberOfNights: differenceInDays(new Date(booking?.reservation?.checkOut), new Date(booking?.reservation?.checkIn)),
    cashAmount: Number(booking?.bookingTotal?.creditCard?.total),
    pointsAmount: Number(booking?.bookingTotal?.points?.totalPoints),
    voucherAmount: Number(booking?.bookingTotal?.voucher?.total),
    creditNoteAmount: Number(booking?.bookingTotal?.creditNote?.total),
    payableLaterAmount: Number((booking?.paymentSchedule ?? [])[0]?.total?.amount || 0),
  };
};

export const trackBookingLogic = createLogic({
  type: trackBooking,
  async process({ action }, dispatch, done) {
    const { booking } = action.payload;
    const bookingTotal = Number(booking?.reservation?.offer?.charges?.payableAtBooking?.total?.amount);

    dispatch(trackEvent({ name: 'booking', value: bookingTotal, properties: splitPropertiesForBooking(booking) }));
    done();
  },
});
