import { createReducer } from '@reduxjs/toolkit';
import { createQuoteSuccess, createQuoteFailure, setLoading, createQuote, clearQuote } from './quoteActions';

const initialState = {
  quote: null,
  isLoading: true,
  error: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(createQuote, (state) => {
      state.error = null;
      state.isLoading = true;
    })
    .addCase(createQuoteSuccess, (state, { payload }) => {
      state.quote = payload.quote;
    })
    .addCase(createQuoteFailure, (state, { payload }) => {
      state.error = payload;
      state.quote = null;
    })
    .addCase(clearQuote, (state) => {
      state.quote = null;
    })
    .addCase(setLoading, (state, { payload }) => {
      state.isLoading = payload;
    }),
);
