import map from 'lodash/map';
import { splitEvent } from 'lib/analytics/gtmEventShapes';
import { emitSplitTreatments } from 'store/split/splitActions';

const emitSplitTreatmentEvent = (action) => {
  const treatments = action.payload || {};
  const splitEvents = map(treatments, (config, split) => splitEvent(split, config));
  return splitEvents.length ? splitEvents : null;
};

export default { [emitSplitTreatments]: emitSplitTreatmentEvent };
