import React from 'react';
import PropTypes from 'prop-types';
import { Image } from '@qga/roo-ui/components';
import { HOTEL_DEALS_URL, HOTELS_LANDING_PAGE_URL } from './constants';
import { CONTACT_US, PRIVACY_AND_SECURITY_URL, TERMS_OF_USE_URL } from './brand';
import AppLink from 'components/AppLink';
import { jetstar } from '@qga/roo-ui/logos';
import FollowUs from '../components/Jetstar/FollowUs';

const NAVIGATION_MENU = [
  {
    trackingName: 'Deals',
    href: HOTEL_DEALS_URL,
    'data-testid': 'deals-link',
    children: 'Deals',
  },
  {
    trackingName: 'Flights',
    href: 'https://www.jetstar.com/au/en/home',
    'data-testid': 'Flights-link',
    children: 'Flights',
  },
  {
    trackingName: 'Hotels',
    'data-testid': 'hotels-link',
    children: 'Hotels',
    href: '/search/list',
    to: '/search/list',
    as: AppLink,
    usesQueryParams: true,
  },
  {
    trackingName: 'Holidays',
    'data-testid': 'holidays-link',
    children: 'Holidays',
    href: 'https://www.jetstar.com/au/en/holidays',
  },
  {
    trackingName: 'Cars',
    'data-testid': 'cars-link',
    children: 'Cars',
    href: 'https://www.jetstar.com/au/en/car-hire?clientId=755074',
  },
];

export const QFF_LINKS = [];

export const DESKTOP_NAVIGATION_MENU = NAVIGATION_MENU;

export const PHONE_NAVIGATION_MENU = [
  ...NAVIGATION_MENU,
  {
    trackingName: 'Contact',
    'data-testid': 'contact-link',
    children: 'Contact',
    href: CONTACT_US,
    to: CONTACT_US,
    as: AppLink,
  },
  {
    trackingName: 'Privacy & Security',
    'data-testid': 'privacy-security-link',
    children: 'Privacy & Security',
    href: PRIVACY_AND_SECURITY_URL,
  },
  {
    trackingName: 'Terms of use',
    'data-testid': 'terms-of-use-link',
    children: 'Terms of use',
    href: TERMS_OF_USE_URL,
  },
  {
    trackingName: 'FAQs',
    'data-testid': 'faqs-link',
    children: 'FAQs',
    href: '/faqs',
    to: '/faqs',
    as: AppLink,
  },
];

const SUPPORT_LINKS = [
  {
    trackingName: 'FAQs',
    'data-testid': 'faqs-link',
    children: 'FAQs',
    href: '/faqs',
    to: '/faqs',
    as: AppLink,
  },
  {
    trackingName: 'Contact',
    'data-testid': 'contact-link',
    children: 'Contact',
    href: CONTACT_US,
    to: CONTACT_US,
    as: AppLink,
  },
];

// const destinations = [
//   'Australia ',
//   'Queensland',
//   'New South Wales',
//   'Victoria',
//   'Tasmania',
//   'Northern Territory',
//   'Western Australia',
//   'South Australia',
// ];

// const DESTINATION_LINKS = destinations.map((name) => ({
//   trackingName: name,
//   'data-testid': `${name}-link`,
//   children: name,
//   href: `/search/list?location=${name}`,
//   to: `/search/list?location=${name}`,
//   as: AppLink,
// }));

const HOTELS_FOOTER_MENU = [
  {
    trackingName: 'Hotels',
    'data-testid': 'hotels-link',
    children: 'Hotels',
    href: '/search/list',
    to: '/search/list',
    as: AppLink,
    usesQueryParams: true,
  },
  {
    trackingName: 'Airbnb',
    'data-testid': 'airbnb-link',
    children: 'Airbnb',
    href: '/?searchType=airbnb',
    to: '/?searchType=airbnb',
    as: AppLink,
    usesQueryParams: true,
  },
  {
    trackingName: 'Deals',
    href: HOTEL_DEALS_URL,
    'data-testid': 'deals-link',
    children: 'Hotel Deals',
  },
];

const LogoItemRenderer = (props) => <Image {...props} alt={props.alt} />;
LogoItemRenderer.propTypes = { alt: PropTypes.string.isRequired };

export const HEADER_LOGO = {
  homepageUrl: HOTELS_LANDING_PAGE_URL,
  homepageLogoSrc: jetstar,
  homepageLogoLabel: 'book hotels and accommodation',
  LogoItemRenderer,
};

export const FOOTER_NAVIGATION_LINKS = [
  {
    title: 'Hotels',
    linkList: HOTELS_FOOTER_MENU,
  },
  {
    title: 'Support',
    linkList: SUPPORT_LINKS,
  },
  // {
  //   title: 'Destinations',
  //   linkList: DESTINATION_LINKS,
  // },
  {
    title: 'Follow us',
    itemRenderer: FollowUs,
  },
];

export const FOOTER_SUPPORT_LINKS = [
  {
    trackingName: 'Privacy & Security',
    'data-testid': 'privacy-security-link',
    children: 'Privacy & Security',
    href: PRIVACY_AND_SECURITY_URL,
  },
  {
    trackingName: 'Terms of use',
    'data-testid': 'term-of-use-link',
    children: 'Terms of use',
    href: TERMS_OF_USE_URL,
  },
];

export const QTA_FOOTER_LINKS = [];
export const QTA_FOOTER_NAVIGATION_LINKS = [];
export const QTA_FOOTER_SUPPORT_LINKS = [];
