import pick from 'lodash/pick';
import * as relatedClient from 'lib/clients/searchRelatedPropertyAvailability';
import * as searchClient from 'lib/clients/searchLocationAvailability';
import * as actions from 'store/recommendedProperty/recommendedPropertyActions';
import { apiRequestFailure } from 'store/error/errorActions';
import { getPropertyId } from 'store/property/propertySelectors';
import { getTreatments } from 'store/split/splitSelectors';
import { getAccessToken, getQhUserId } from 'store/user/userSelectors';
import { createAsyncLogic } from 'lib/logic';
import { getFullKnownQuery } from 'store/router/routerSelectors';

const config = {
  popular_properties: {
    buildParams: (params) => ({ ...params, sortBy: 'popularity', limit: 4, page: 1 }),
    client: searchClient.searchLocationAvailability,
  },
  related_properties: {
    buildParams: (params) => pick(params, ['propertyId', 'adults', 'children', 'infants', 'payWith', 'checkIn', 'checkOut']),
    client: relatedClient.searchRelatedPropertyAvailability,
  },
};

export const fetchRecommendedPropertiesLogic = createAsyncLogic({
  type: actions.fetchRecommendedPropertyAvailability,
  latest: true,
  async process({ getState }, dispatch) {
    const state = getState();
    const query = getFullKnownQuery(state);
    const treatments = getTreatments(state);
    const checkIn = query.checkIn;
    const checkOut = query.checkOut;
    const treatment = treatments?.property_page_recommendations?.treatment;

    if (checkIn && checkOut) {
      const { buildParams, client } = config[treatment] || {};

      if (!client) {
        throw new Error(`Invalid property_page_recommendations treatment: ${treatment}`);
      }

      dispatch(actions.setLoading(true));

      const accessToken = getAccessToken(state);
      const qhUserId = getQhUserId(state);
      const propertyId = getPropertyId(state);
      const params = buildParams({ ...query, propertyId, accessToken, qhUserId });

      const { results } = await client(params);
      dispatch(actions.fetchRecommendedPropertyAvailabilitySuccess(results));
    }
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchRecommendedPropertyAvailabilityFailure(parsedError));
    dispatch(apiRequestFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
