import * as actions from 'store/exclusiveOffer/exclusiveOfferActions';
import { setExclusive } from 'store/property/propertyActions';
import * as errorActions from 'store/error/errorActions';
import * as client from 'lib/clients/fetchExclusiveOffer';
import { createAsyncLogic } from 'lib/logic';
import { getPropertyId } from 'store/property/propertySelectors';

export const fetchExclusiveOfferLogic = createAsyncLogic({
  type: actions.fetchExclusiveOffer,
  latest: true,
  async process({ getState, action }, dispatch) {
    dispatch(actions.setLoading(true));
    dispatch(errorActions.clearErrors());

    const state = getState();
    const propertyId = getPropertyId(state);
    const { payload } = action;

    const exclusiveOffer = await client.fetchExclusiveOffer({ propertyId, ...payload });
    dispatch(actions.setExclusiveOffer(exclusiveOffer));
    dispatch(setExclusive(true));
  },
  onError({ parsedError }, dispatch) {
    dispatch(errorActions.apiRequestFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
