import pick from 'lodash/pick';
import { createStructuredSelector } from 'reselect';
import { querySelectors } from 'store/router/routerSelectors';

export const getSearchQuery = createStructuredSelector(
  pick(querySelectors, [
    'location',
    'checkIn',
    'checkOut',
    'adults',
    'children',
    'infants',
    'page',
    'sortBy',
    'propertyTypes',
    'minStarRating',
    'minTripadvisorRating',
    'payWith',
    'minPrice',
    'maxPrice',
    'featuredPropertyId',
    'swLat',
    'swLng',
    'neLat',
    'neLng',
    'facilities',
    'freeCancellation',
    'depositPay',
    'classicRewards',
    'subRegions',
    'travelType',
    'dealType',
  ]),
);
