import { jetstarHotelsLight } from '@qga/roo-ui/logos';

export const FAVICON_HREF = `https://www.jetstar.com/favicon.ico`;
export { jetstarBaggageIconSvg as luggageImage } from 'assets';
export const CONTACT_US = '/contact-us';
export const DESTINATIONS_AUSTRALIA = '';
export const TERMS_AND_CONDITIONS = `/terms-and-conditions`;
export const QH_SELF_SERVICE_URL = `/manage/bookings`;
export const TERMS_AND_CONDITIONS_URL = 'https://www.qantas.com/hotels/terms-and-conditions'; // temporary redirection to old hotels site, until new page has been set up
export const PRIVACY_AND_SECURITY_URL = 'https://www.jetstar.com/au/en/privacy-policy';
export const TERMS_OF_USE_URL = 'https://www.jetstar.com/au/en/terms-of-use?pid=mainfooter:terms-of-use';
export const ABN = 'Jetstar Airways Pty Ltd. ABN: 33 069 720 243';
export const FOOTER_LOGO = jetstarHotelsLight;
export const FOOTER_LOGO_LABEL = 'jetstar hotels logo';
export const DOMESTIC_PHONE_NUMBER = '1300 722 327';
export const INTERNATIONAL_PHONE_NUMBER = '+61 2 8059 0181';

export const BRAND_NAME = 'Jetstar';
export const HOTELS_BRAND_NAME = 'Jetstar Hotels';
export const HOTELS_COMPANY_NAME = 'Jetstar Pty Ltd';
export const HOLIDAYS_BRAND_NAME = 'Jetstar Holidays';

export const BRAND_CODE = 'jetstar';
export const HOTELS_BRAND_BOOKING_CHANNEL = 'jetstar_hotels_website';
export const TRACKING_PREFIX = 'JQ';
export const LUXE_BRAND_BOOKING_CHANNEL = 'qantas_luxe_website';
