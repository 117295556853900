import React, { Fragment } from 'react';
import { Global } from '@emotion/core';
import printStyles from 'components/BookingConfirmationPage/printStyles';
import Disclaimer from './Disclaimer';
import { Footer as GlobalFooter } from '@qga/components';
import {
  HOTELS_LANDING_PAGE_URL,
  ABN,
  FOOTER_LOGO,
  FOOTER_LOGO_LABEL,
  FOOTER_NAVIGATION_LINKS,
  FOOTER_SUPPORT_LINKS,
  QTA_FOOTER_NAVIGATION_LINKS,
  QTA_FOOTER_SUPPORT_LINKS,
} from 'config';
import { getIsMobileApp } from 'store/ui/uiSelectors';
import { useSelector } from 'react-redux';
import { useDataLayer } from 'hooks/useDataLayer';
import { useQueryString } from 'hooks/useQueryString';
import useMenuClickEvent from 'hooks/useMenuClickEvent';

const Footer = () => {
  const isMobileApp = useSelector(getIsMobileApp);
  const { emitInteractionEvent } = useDataLayer();
  const queryString = useQueryString();

  const { menuClickEvent } = useMenuClickEvent();

  const footerLinks = isMobileApp ? QTA_FOOTER_NAVIGATION_LINKS : FOOTER_NAVIGATION_LINKS;
  const supportLinks = isMobileApp ? QTA_FOOTER_SUPPORT_LINKS : FOOTER_SUPPORT_LINKS;

  return (
    <Fragment>
      <Global styles={printStyles} />
      <Disclaimer />
      <GlobalFooter
        landingPageUrl={HOTELS_LANDING_PAGE_URL}
        links={footerLinks}
        disclaimerLinks={supportLinks}
        abn={ABN}
        footerLogoSrc={FOOTER_LOGO}
        footerLogoLabel={FOOTER_LOGO_LABEL}
        onInteraction={emitInteractionEvent}
        onMenuClick={menuClickEvent}
        queryString={queryString}
      />
    </Fragment>
  );
};

export default Footer;
