import { createReducer } from '@reduxjs/toolkit';
import { setCalendar, clearCalendar } from './calendarActions';

const initialState = {
  calendar: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setCalendar, (state, { payload }) => {
      const { stays, ...rest } = payload;
      const allCalendarStays = [...stays, ...(state?.calendar?.stays || [])];
      const uniqueStays = [...new Map(allCalendarStays.map((stay) => [stay.date, stay])).values()];

      state.calendar = { ...rest, stays: uniqueStays };
    })
    .addCase(clearCalendar, (state) => {
      state.calendar = null;
    }),
);
