import { createReducer } from '@reduxjs/toolkit';
import { clearPointsBurnTiers, setPointsBurnTiers } from './pointsBurnActions';

const initialState = {
  levels: [],
  name: '',
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setPointsBurnTiers, (state, { payload }) => {
      state.levels = payload?.levels;
      state.name = payload?.name;
    })
    .addCase(clearPointsBurnTiers, (state) => {
      state.levels = [];
      state.name = '';
    }),
);
