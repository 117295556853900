import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageError from 'components/errors/PageError';
import { captureErrorInSentry } from 'lib/errors';
import { getApiError } from 'store/error/errorSelectors';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: this.props.error };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
  }

  componentDidCatch(error, errorInfo) {
    captureErrorInSentry(error, errorInfo);
  }

  render() {
    return this.state.error ? <PageError {...this.state.error} /> : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  error: PropTypes.object,
};

ErrorBoundary.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  error: getApiError(state),
});

export default connect(mapStateToProps)(ErrorBoundary);
