import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import loadClientType from 'server/lib/populateReduxStore/handlers/loadClientType';

const ClientsideReduxUpdater: FC = () => {
  const dispatch = useDispatch();
  const { query } = useRouter() || {};

  // Update isMobileApp at run time
  useEffect(() => {
    loadClientType({ store: { dispatch }, query });
  }, [query, dispatch]);

  return null;
};

export default ClientsideReduxUpdater;
