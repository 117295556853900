import { theme as baseTheme } from '@qga/roo-ui';
import merge from 'lodash/merge';

import * as icons from './icons';
import { zIndices } from './zIndices';
import { colors } from './colors';
import { fontSizes } from './fontSizes';
import { shadows } from './shadows';
import { radii } from './radii';
import { slider } from './slider';
import { badge } from './badge';
import { card } from './card';
import { textStyles, fontFamily } from './textStyles';
import { buttons } from './buttons';
import { maxWidths, breakpoints, mediaQueries, uiStructure, imageSizes } from './sizing';
import { focus } from './focus';
import { pagination } from './pagination';

// based on qantas theme - https://github.com/hooroo/roo-ui/blob/master/src/theme.js

export const theme = merge({}, baseTheme, {
  maxWidths,
  fontSizes,
  focus,
  breakpoints,
  mediaQueries,
  shadows,
  uiStructure,
  zIndices,
  radii,
  imageSizes,
  colors,
  icons,
  slider,
  badge,
  card,
  fontFamily,
  textStyles,
  buttons,
  pagination,
});
