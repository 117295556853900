export const interactionEvent = ({ type, value, page, customAttributes }) => {
  return {
    event: 'user_interactions',
    user_event_category: type,
    user_event_action: value,
    user_event_label: page,
    ...customAttributes,
  };
};

export const splitEvent = (splitName, config) => {
  return {
    event: 'user_interactions',
    user_event_category: `split:${splitName}`,
    user_event_action: 'split:start',
    user_event_label: `split:${splitName}:${config.treatment}`,
  };
};

export const scrollTrackingEvent = ({ pixels, distance, page }) => {
  return {
    event: 'scrollTracking',
    attributes: {
      pixels,
      distance,
      label: page,
    },
  };
};
