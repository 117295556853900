import { createSelector } from '@reduxjs/toolkit';
import { find, map, reduce, sum, uniqBy } from 'lodash';
import buildPropertyCardsData from 'lib/search/buildPropertyCardsData';
import mapDealsToCategories from 'lib/search/mapDealsToCategories';
import {
  getQueryAdults,
  getQueryCheckIn,
  getQueryCheckOut,
  getQueryChildren,
  getQueryInfants,
  getQueryLocation,
  getQuerySortBy,
} from 'store/router/routerSelectors';

const filterUniqueResultsByProperty = (results) => uniqBy(results, 'property.id');

export const getActiveCategory = (state) => state.promoArea.activeCategory;
export const getComponentState = (state) => state.promoArea.componentState;

export const getDeals = (state) => state.promoArea.deals;

export const getResults = createSelector(getDeals, (deals) =>
  deals.map(({ type, meta, results }) => ({
    type,
    meta,
    results: filterUniqueResultsByProperty(results),
  })),
);

export const getIsLoading = (state) => state.promoArea.isLoading;
export const getHasResults = createSelector(getResults, (results) => {
  const totalResultsCount = sum(map(results, (category) => category.results.length));

  /**
   * Note: The total has to be greater than one, as we don't display with one result
   */
  return totalResultsCount > 1;
});

const getCategorisedResults = createSelector(getResults, mapDealsToCategories);

export const getCategories = createSelector(getCategorisedResults, (results) => results.map((result) => result.type));
export const getResultsForActiveCategory = createSelector(getCategorisedResults, getActiveCategory, (results, activeCategory) => {
  const categoryResults = find(results, ['type.code', activeCategory.code]);

  if (!categoryResults?.results) return [];

  const firstDeals = categoryResults.results.slice(0, 3);
  const result = buildPropertyCardsData({ results: firstDeals });

  return result;
});

export const getLocationDealsQuery = createSelector(
  getQueryLocation,
  getQueryAdults,
  getQueryChildren,
  getQueryInfants,
  getQueryCheckIn,
  getQueryCheckOut,
  getQuerySortBy,
  (location, adults, children, infants, checkIn, checkOut, sortBy) => ({ location, adults, children, infants, checkIn, checkOut, sortBy }),
);

export const getGa4PromoResults = createSelector(getResultsForActiveCategory, (results) => {
  const ga4Results = reduce(
    results,
    (accum, result) => {
      const mappedProperty = {
        property: { id: result?.id, name: result?.propertyName, hasOffer: result?.promotionName ? true : false },
        roomType: { name: '' },
        offer: {
          charges: { total: result?.total, totalCash: result?.totalCash ? result?.totalCash : { amount: '0', currency: 'AUD' } },
        },
      };
      return [...accum, mappedProperty];
    },
    [],
  );

  return ga4Results;
});
