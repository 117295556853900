import * as campaignsClient from 'lib/clients/fetchCampaign';
import { fetchCampaign, setCampaign, clearCampaign } from './campaignActions';
import { getTreatments } from 'store/split/splitSelectors';
import isEmpty from 'lodash/isEmpty';
import { createAsyncLogic } from 'lib/logic';
import { VPP_CAMPAIGN } from 'config';

const fetchCampaignLogic = createAsyncLogic({
  type: fetchCampaign,
  latest: true,
  async process({ getState }, dispatch) {
    const campaign = await campaignsClient.fetchCampaign();

    if (!isEmpty(campaign)) {
      dispatch(setCampaign(campaign));
    } else {
      const { blue_banner_message: localCampaign, vpp } = getTreatments(getState());
      const localCampaignConfig = isEmpty(localCampaign) ? null : localCampaign.config;

      if (vpp?.treatment === 'on') {
        dispatch(setCampaign(VPP_CAMPAIGN));
      } else if (localCampaign?.treatment === 'local_campaign_live' && !isEmpty(localCampaignConfig)) {
        dispatch(setCampaign(localCampaignConfig));
      } else {
        dispatch(clearCampaign());
      }
    }
  },
  onError(context, dispatch) {
    dispatch(clearCampaign());
  },
});

export const logic = [fetchCampaignLogic];
