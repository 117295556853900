import { createAction } from '@reduxjs/toolkit';

export const clearDestination = createAction('destination/CLEAR_DESTINATION');
export const fetchDestination = createAction('destination/FETCH_DESTINATION');
export const fetchDestinationFailure = createAction('destination/FETCH_DESTINATION_FAILURE');
export const setDestination = createAction('destination/SET_DESTINATION');

export const clearMerchandise = createAction('destination/CLEAR_MERCHANDISE');
export const fetchMerchandise = createAction('destination/FETCH_MERCHANDISE');
export const fetchMerchandiseFailure = createAction('destination/FETCH_MERCHANDISE_FAILURE');
export const setMerchandise = createAction('destination/SET_MERCHANDISE');
export const emitLatestDealsResults = createAction('destination/EMIT_LATEST_DEALS_RESULTS');
