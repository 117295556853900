import { createReducer } from '@reduxjs/toolkit';
import { clearDeals, clearPageContent, setDeals, setDealType, setError, setPageContent, setStatus } from './dealActions';
import { STORE_STATUS } from 'lib/enums/store';
import { DEFAULT_DEAL_TYPE } from 'config';

const initialState = {
  deals: null,
  dealType: DEFAULT_DEAL_TYPE,
  error: null,
  status: STORE_STATUS.PENDING,
  pageContent: null,
};

export const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(clearDeals, (state) => {
      state.deals = null;
      state.error = null;
      state.status = STORE_STATUS.PENDING;
    })
    .addCase(clearPageContent, (state) => {
      state.pageContent = null;
    })
    .addCase(setDeals, (state, { payload }) => {
      state.deals = payload;
      state.error = null;
    })
    .addCase(setDealType, (state, { payload }) => {
      state.dealType = payload;
    })
    .addCase(setError, (state, { payload }) => {
      state.error = payload;
    })
    .addCase(setPageContent, (state, { payload }) => {
      state.pageContent = payload;
    })
    .addCase(setStatus, (state, { payload }) => {
      state.status = payload;
    }),
);
