import React, { useCallback } from 'react';
import { Box, Flex } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';
import { DisclaimerLink, DisclaimerText } from 'components/Footer/DisclaimerItems';
import { QL_TRAVEL_INSURANCE_POLICIES, QL_NZ_TRAVEL_INSURANCE_POLICIES } from 'config';

const TravelInsuranceDisclaimers = () => {
  const { emitInteractionEvent } = useDataLayer();

  const handleOnClickProductDisclosure = useCallback(() => {
    emitInteractionEvent({ type: 'Travel insurance product disclosure', value: 'Travel insurance product disclosure selected' });
  }, [emitInteractionEvent]);

  const handleOnClickTargeteMarketDetermination = useCallback(() => {
    emitInteractionEvent({
      type: 'Travel insurance target market determination',
      value: 'Travel insurance target market determination selected',
    });
  }, [emitInteractionEvent]);

  const handleOnClickPolicyDocument = useCallback(() => {
    emitInteractionEvent({
      type: 'Travel insurance policy document',
      value: 'Travel insurance policy document selected',
    });
  }, [emitInteractionEvent]);

  const travelInsuranceDisclaimer = `≈ Qantas Travel Insurance policies are managed by nib Travel Services (Australia) Pty Limited (nib) ABN 81 115 932 173 AFSL 308461 and underwritten by Pacific International Insurance Pty Ltd (Pacific), ABN 83 169 311 193, NZBN 9429041356500. In New Zealand, Pacific is a member of the Insurance and Financial Services Ombudsman dispute resolution scheme. Qantas (AR 261363) is nib’s authorised agent and acts on behalf of nib. Before you buy you should consider your personal circumstances as well as the `;
  const travelInsuranceAndPointsDisclaimer =
    ' Qantas Frequent Flyer (QFF) members who are the primary holder of a Qantas Travel Insurance policy will earn 1 Qantas Point per A$1 value of the premium paid. Excludes Cancellation and Baggage policies. Qantas Points will be credited within 6 weeks after the trip departure date listed on your policy. Qantas may amend or withdraw this offer at any time.';
  return (
    <Flex flexDirection="column" style={{ gap: '2rem' }}>
      <Box data-testid="travel-insurance-disclaimer">
        <DisclaimerText>{travelInsuranceDisclaimer}&nbsp;</DisclaimerText>
        <DisclaimerLink data-testid="product-disclosure-link" href={QL_TRAVEL_INSURANCE_POLICIES} onClick={handleOnClickProductDisclosure}>
          <DisclaimerText>Product Disclosure Statement (PDS)</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText> and </DisclaimerText>
        <DisclaimerLink
          data-testid="target-market-determination-link"
          href={QL_TRAVEL_INSURANCE_POLICIES}
          onClick={handleOnClickTargeteMarketDetermination}
        >
          <DisclaimerText>Target Market Determination (TMD)</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText> in Australia, or </DisclaimerText>
        <DisclaimerLink data-testid="policy-document-link" href={QL_NZ_TRAVEL_INSURANCE_POLICIES} onClick={handleOnClickPolicyDocument}>
          <DisclaimerText>Policy Document</DisclaimerText>
        </DisclaimerLink>
        <DisclaimerText> in New Zealand.</DisclaimerText>
      </Box>
      <DisclaimerText data-testid="travel-insurance-points-disclaimer">{travelInsuranceAndPointsDisclaimer}&nbsp;</DisclaimerText>
    </Flex>
  );
};

export default TravelInsuranceDisclaimers;
