import wrappedAxios from './wrappedAxios';
import { HOTELS_API_URL } from 'config';

export const fetchDestination = async ({ country, state, area }) => {
  const response = await wrappedAxios({
    url: `${HOTELS_API_URL}/destinations`,
    method: 'GET',
    params: {
      country,
      state,
      area,
    },
  });

  return response?.data;
};
