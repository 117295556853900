import { rem } from 'polished';
import { colors } from './colors';
import { fontSizes } from './fontSizes';
import { zIndices } from './zIndices';

export const card = {
  inline: {
    root: {
      position: 'relative',
      marginTop: rem('15px'),
    },
    badge: {
      position: 'absolute',
      top: rem('-13px'),
      left: 0,
      zIndex: zIndices.dropdownContent,
    },
    defaultImage: {
      width: 135,
    },
    text: {
      padding: '1rem',
    },
  },
  stacked: {
    root: {
      marginTop: rem('15px'),
    },
    badge: {
      top: rem('-13px'),
      left: 0,
      zIndex: zIndices.dropdownContent,
    },
  },
  propertyCard: {
    propertyName: {
      color: colors.greys.charcoal,
      fontSize: fontSizes.sm,
      marginBottom: 0,
    },
    offerBreakdown: {
      fontSize: fontSizes.xs,
    },
    freeCancellationWrapper: {
      minHeight: rem('40px'),
    },
    offerWrapper: {
      display: 'flex',
      textAlign: 'right',
      marginTop: rem('0px'),
      marginBottom: rem('5px'),
    },
  },
  searchResultCard: {
    root: {
      marginTop: rem('24px'),
    },
    sashContainer: {
      position: 'absolute',
      top: rem('-13px'),
      left: 0,
    },
  },
};
