import { getPageFromState } from './helpers';
import { interactionEvent } from 'lib/analytics/gtmEventShapes';
import { trackAuthentication, login, loginFailure, logout } from 'store/user/userActions';
import hashMemberId from 'lib/analytics/hashMemberId';

const emitSetAuthenticationEvent = ({ payload }, prevState) => {
  const { memberId } = payload;
  const page = getPageFromState(prevState);

  return interactionEvent({
    type: 'Frequent Flyer Authentication',
    value: 'Authentication Succeeded',
    page: page.name,
    customAttributes: {
      customerId: hashMemberId(memberId),
    },
  });
};

const emitLoginSuccessEvent = (_, prevState) => {
  const page = getPageFromState(prevState);

  return interactionEvent({
    type: 'Frequent Flyer Login',
    value: 'Login Succeeded',
    page: page.name,
    customAttributes: {
      qffLoggedInState: 'Successfully Logged in',
    },
  });
};

const emitLoginFailureEvent = (_, prevState) => {
  const page = getPageFromState(prevState);

  return interactionEvent({
    type: 'Frequent Flyer Login',
    value: 'Login Failed',
    page: page.name,
  });
};

const emitLogoutEvent = (action, prevState) => {
  const page = getPageFromState(prevState);
  //to prevent from firing a second time with initCompleted after logoutSuccess.
  if (!action.payload?.emitAnalyticsEvent) return null;

  return interactionEvent({
    type: 'Frequent Flyer Login',
    value: 'Logout',
    page: page.name,
  });
};

export default {
  [trackAuthentication]: emitSetAuthenticationEvent,
  [login]: emitLoginSuccessEvent,
  [loginFailure]: emitLoginFailureEvent,
  [logout]: emitLogoutEvent,
};
