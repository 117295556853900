import { createLogic } from 'redux-logic';

import { getPropertyPageRecommendationsTreatment, getRecommendedPropertyAvailabilityCardsData } from '../recommendedPropertySelectors';
import { fetchRecommendedPropertyAvailabilitySuccess } from '../recommendedPropertyActions';
import { registerImpressions } from '../../../lib/analytics/recommendations';

export const trackRecommendedPropertyImpressionsLogic = createLogic({
  type: fetchRecommendedPropertyAvailabilitySuccess,
  async process({ getState }, dispatch, done) {
    const state = getState();
    const treatment = getPropertyPageRecommendationsTreatment(state);
    const properties = getRecommendedPropertyAvailabilityCardsData(state);
    const propertyIds = properties.map((property) => property.id);
    registerImpressions({ propertyIds, method: treatment, placement: 'property_page' });
    done();
  },
});
