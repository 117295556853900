import * as client from 'lib/clients/searchLocationAvailability';
import * as actions from 'store/search/searchActions';
import * as splitActions from 'store/split/splitActions';
import tierInstance from 'store/pointsConversion/tierInstance';
import { LIST_SEARCH_LIMIT } from 'config';
import { getSearchType } from 'store/search/searchSelectors/searchSelectors';
import { getSearchQuery } from 'store/search/searchSelectors';
import { getAccessToken, getFlightBookerToken, getQhUserId } from 'store/user/userSelectors';
import { getBrowser } from 'store/userEnvironment/userEnvironmentSelectors';
import { setPointsLevels } from 'store/pointsConversion/pointsConversionActions';
import { createAsyncLogic } from 'lib/logic';

export const fetchSearchResultsLogic = createAsyncLogic({
  type: actions.fetchSearchResults,
  cancelType: actions.cancelFetchSearchResults,
  latest: true,
  async process({ getState, action }, dispatch) {
    dispatch(actions.setLoading(true));

    const { limit = LIST_SEARCH_LIMIT } = action.payload || {};
    const state = getState();
    const accessToken = getAccessToken(state);
    const flightBookerToken = getFlightBookerToken(state);
    const qhUserId = getQhUserId(state);
    const query = getSearchQuery(state);

    const { results, meta } = await client.searchLocationAvailability({ ...query, limit, accessToken, flightBookerToken, qhUserId });

    dispatch(actions.fetchSearchResultsSuccess({ results, meta }));

    const activeTierInstance = tierInstance(meta.pointsTierInstances);

    dispatch(setPointsLevels(activeTierInstance));

    dispatch(
      splitActions.trackEvent({
        name: 'search',
        value: null,
        properties: {
          browserType: getBrowser(state).type,
          page: query.page || 1,
          searchType: getSearchType(state),
        },
      }),
    );
  },
  onError({ parsedError }, dispatch) {
    dispatch(actions.fetchSearchResultsFailure(parsedError));
  },
  onFinally(context, dispatch) {
    dispatch(actions.setLoading(false));
  },
});
