import { logic as searchLogic } from './search/searchLogic';
import { logic as propertyLogic } from './property/propertyLogic';
import { logic as userLogic } from './user/userLogic';
import { logic as fetchSiteMessageLogic } from './siteMessage/fetchSiteMessageLogic';
import { logic as splitLogic } from './split/splitLogic';
import { logic as fetchCampaignLogic } from './campaign/fetchCampaignLogic';
import { logic as propertyAvailabilityLogic } from './propertyAvailability/propertyAvailabilityLogic';
import { logic as quoteLogic } from './quote/quoteLogic';
import { logic as userEnvironmentLogic } from './userEnvironment/userEnvironmentLogic';
import { logic as bookingLogic } from './booking/bookingLogic';
import { logic as routerLogic } from './router/routerLogic';
import { logic as enquiryLogic } from './enquiry/enquiryLogic';
import { logic as checkoutLogic } from './checkout/checkoutLogic';
import { logic as destinationLogic } from './destination/destinationLogic';
import { logic as memberFavouriteLogic } from './memberFavourites/memberFavouritesLogic';
import { logic as uiLogic } from './ui/uiLogic';
import { logic as promoAreaLogic } from './promoArea/promoAreaLogic';
import { logic as recommendedPropertyLogic } from './recommendedProperty/recommendedPropertyLogic';
import { logic as personalisationLogic } from './personalisation/personalisationLogic';
import { logic as contentLogic } from './content/contentLogic';
import { logic as faqsLogic } from './faqs/faqLogic';
import { logic as exclusiveOfferLogic } from './exclusiveOffer/exclusiveOfferLogic';
import { logic as termsAndConditionsLogic } from './termsAndConditions/termsAndConditionsLogic';
import { logic as dealLogic } from './deal/dealLogic';
import { logic as calendarLogic } from './calendar/fetchCalendarLogic';
import { logic as pointsBurnTiersLogic } from './pointsBurnTiers/pointsBurnTiersLogic';

export default [
  ...searchLogic,
  ...propertyLogic,
  ...userLogic,
  ...fetchSiteMessageLogic,
  ...splitLogic,
  ...fetchCampaignLogic,
  ...propertyAvailabilityLogic,
  ...quoteLogic,
  ...userEnvironmentLogic,
  ...bookingLogic,
  ...routerLogic,
  ...enquiryLogic,
  ...checkoutLogic,
  ...destinationLogic,
  ...memberFavouriteLogic,
  ...uiLogic,
  ...promoAreaLogic,
  ...recommendedPropertyLogic,
  ...personalisationLogic,
  ...contentLogic,
  ...faqsLogic,
  ...exclusiveOfferLogic,
  ...termsAndConditionsLogic,
  ...dealLogic,
  ...calendarLogic,
  ...pointsBurnTiersLogic,
];
