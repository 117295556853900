import React from 'react';
import { appStore, googlePlay } from '@qga/roo-ui/assets';
import { Flex, Icon, Text, Link, Image } from '@qga/roo-ui/components';
import { useDataLayer } from 'hooks/useDataLayer';

export const SOCIAL_LINKS = [
  {
    id: 'facebook',
    url: 'https://www.facebook.com/JetstarAustralia',
  },
  {
    id: 'instagram',
    url: 'https://www.instagram.com/jetstaraustralia/',
  },
  {
    id: 'twitter',
    url: 'https://twitter.com/jetstarairways',
  },
  {
    id: 'youtube',
    url: 'https://www.youtube.com/jetstarairways',
  },
];

const FollowUs = () => {
  const { emitInteractionEvent } = useDataLayer();
  const handleSocialClick = (id) => {
    emitInteractionEvent({ type: 'Footer Navigation', value: `Follow on ${id} Selected` });
  };
  const handleAppStoreClick = (id) => {
    emitInteractionEvent({ type: 'Footer Navigation', value: `Download app on ${id} Selected` });
  };

  return (
    <Flex alignItems="flex-start" flexDirection="column">
      <Flex mb={9}>
        {SOCIAL_LINKS.map(({ id, url }) => (
          <Link
            key={`follow-${id}-link`}
            data-testid={`follow-${id}-link`}
            href={url}
            aria-label={`Follow us on ${id}`}
            onClick={() => handleSocialClick(id)}
            mr={5}
          >
            <Icon name={id} color="white" />
          </Link>
        ))}
      </Flex>
      <Text color="white" fontSize="sm" fontWeight="bold">
        Download the Jetstar app
      </Text>
      <Link
        data-testid="app-store-link"
        href="https://apps.apple.com/au/app/jetstar/id821234247"
        aria-label="Download the Jetstar app in the App Store"
        onClick={() => handleAppStoreClick('App Store')}
        mt={4}
      >
        <Image height="40px" src={appStore} alt="app store icon" />
      </Link>
      <Link
        data-testid="google-play-link"
        href="https://play.google.com/store/apps/details?id=com.ink.jetstar.mobile.app"
        aria-label="Download the Jetstar app in Google Play"
        onClick={() => handleAppStoreClick('Google Play')}
        mt={4}
      >
        <Image height="40px" src={googlePlay} alt="google play icon" />
      </Link>
    </Flex>
  );
};

export default FollowUs;
