import cookies from 'js-cookie';
import { setAuthentication, trackAuthentication, setAuthenticationCompleted, clearAuthentication } from 'store/user/userActions';
import fetchMemberDetails from 'lib/clients/fetchMemberDetails';
import { COOKIE_NAMES } from 'lib/enums/cookies';
import { get } from 'lodash';

const authenticateUserOAuth = async (dispatch, getState: () => unknown) => {
  const uid = cookies?.get(COOKIE_NAMES.QH_AUTH_MEMBER_ID);

  if (typeof uid !== 'string') {
    dispatch(clearAuthentication());
  } else {
    try {
      const memberDetails = await fetchMemberDetails({ memberId: uid });
      if (typeof get(getState(), 'user.authentication.memberId') !== 'string') dispatch(trackAuthentication({ memberId: uid }));
      dispatch(setAuthentication({ memberId: uid, ...memberDetails }));
    } catch (e) {
      dispatch(clearAuthentication());
    }
  }

  dispatch(setAuthenticationCompleted());
};

export default authenticateUserOAuth;
