import { SNARE_JS_URL } from 'config';
import { loadScript } from 'lib/script';

let promise;

export const getDeviceFingerprint = () => {
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      // globals required for the ReD fraud device fingerprint
      window.io_enable_rip = true; // enable collection of Real IP
      window.io_install_flash = false; // do not require install of Flash
      window.io_install_stm = false; // do not require install of Active X
      window.io_bb_callback = (deviceFingerprint) => resolve(deviceFingerprint);

      if (SNARE_JS_URL) {
        loadScript({ url: `//${SNARE_JS_URL}` }).catch((e) => {
          reject(e);
        });
      } else {
        reject(new Error('No url for snare.js'));
      }
    });
  }

  return promise;
};
